import React from "react";
import { Link } from "react-router-dom";

//MUI
import { Box, Grid, Typography, Link as MuiLink } from "@mui/material";

//Custome Components
import PageBreadcrumbs from "../Breadcrumbs";

//Images and Icons
import Dummy from "../../../Assets/dummy.jpg";

const InnerBanner = ({
  type,
  title,
  desc,
  image,
  bannertext,
  backgroundPosition,
}) => {
  if (type === "fullimage") {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            flexDirection: "column",
            marginLeft: "24px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "24px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 48px)",
              md: "calc(100% - 24px)",
            },
          }}
        >
          <Grid sx={{ marginTop: "120px" }}>
            <PageBreadcrumbs />
          </Grid>
          <Grid>
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: backgroundPosition,
                backgroundSize: "cover",
                minHeight: "450px",
                marginLeft: {
                  xs: "0",
                  md: "10%",
                },
                boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
              }}
            ></Box>
          </Grid>
          {bannertext ? (
            <Grid>
              <Box sx={{ marginTop: "50px", maxWidth: "780px" }}>
                <Typography variant="h3">{title}</Typography>
                <Typography>{desc}</Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            marginLeft: "24px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "50px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 48px)",
              md: "calc(100% - 24px)",
            },
          }}
        >
          <Grid
            item
            xs="12"
            md="5"
            sx={{
              alignSelf: "end",
              marginBottom: "50px",
              marginTop: {
                xs: "120px",
              },
            }}
          >
            <PageBreadcrumbs />
            <Typography
              variant="h3"
              sx={{
                textWrap: "balance",
                lineHeight: "1.2",
                marginBottom: "30px",
              }}
            >
              {title}
            </Typography>
            <Typography sx={{ textWrap: "balance" }}>{desc}</Typography>
          </Grid>
          <Grid
            item
            xs="12"
            md="6"
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: "1/.7",
              boxShadow: {
                xs: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
                md: "0px 60px 15px -48px rgba(0, 0, 0, 0.0)",
              },
            }}
          ></Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

InnerBanner.defaultProps = {
  title: "Page Title",
  desc: "Page desc",
  image: Dummy,
  bannertext: true,
  backgroundPosition: "center",
};

export default InnerBanner;
