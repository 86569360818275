import React from "react";
import { Typography, Box } from "@mui/material";

import { Parallax } from "react-parallax";

import Dummy from "../../Assets/dummy.jpg";

const SectionScrolling = ({ bgimg, title, highlight }) => {
  const boldSection = highlight; // Specify the section you want to be bolded here
  const [firstPart, secondPart] = title.split(boldSection);

  // Scroll Animation

  return (
    <React.Fragment>
      <Parallax
        blur={{ min: 15, max: -15 }}
        bgImage={bgimg}
        bgImageAlt={secondPart}
        strength={-400}
      >
        <Box
          sx={{
            height: {
              xs: "100vh",
              sm: "80vh",
              md: "70vh",
            },
          }}
        >
          <Typography
            sx={{
              textWrap: "balance",
              fontSize: {
                xs: "26px",
                sm: "32px",
                md: "36px",
              },
              fontWeight: 400,
              maxWidth: "700px",
              position: "absolute",
              top: "50px",
              left: "50px",
              "& span": {
                fontSize: {
                  xs: "30px",
                  sm: "36px",
                  md: "40px",
                },
                fontWeight: 700,
              },
            }}
          >
            {firstPart}
            <span>{boldSection}</span>
            {secondPart}
          </Typography>
        </Box>
      </Parallax>
    </React.Fragment>
  );
};

SectionScrolling.defaultProps = {
  title: "add the title",
  bgimg: Dummy,
};

export default SectionScrolling;
