import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import { Container, Typography, Grid } from "@mui/material";

const AboutUs = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          title={"About Us"}
          desc={
            "Modular interiors have become a game-changer in the Construction Industry, offering unmatched versatility, functionality & affordability. At Ambiance Decorio, we fully embrace the potential of modular design to transform spaces into extraordinary works of art. Our modular interior solutions are meticulously crafted to optimize space utilization, enhance flexibility, and bring efficiency to your environment. "
          }
        />
        <Section>
          <Container maxWidth={"xl"}>
            <Typography></Typography>
            <Typography>
              We draw inspiration from diverse sources to create spaces that are
              as distinctive as they are functional. Whether it's a residential
              masterpiece or a commercial marvel, our team of talented
              professionals will transform your vision into reality.
            </Typography>
            <Typography>
              ID & Er. Krishna Prasad M. (Member IIID/ACCEI/CREDAI) is the
              visionary behind Ambiance Decorio, is an esteemed Interior
              Designer with an extensive career spanning over 15+ years. His
              expertise in the field of interiors and architecture encompasses a
              wide range of domestic and international projects.
            </Typography>
            <Typography>
              Our success lies in our exceptional workforce. At Ambiance
              Decorio, we have always sought out the most inspired and talented
              professionals in the industry to represent our brand. It is
              through this collective endeavour that our projects will continue
              to stand as lasting testimonials to our success.
            </Typography>
          </Container>

          <Container maxWidth={"xl"} sx={{ marginTop: "70px" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography variant="h5">Vision</Typography>
                <Typography>
                  Creating Beautiful, Functional, Sustainable & affordable
                  EXCLUSIVE MODULAR INTERIORS to every Home & Commercial Spaces.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography variant="h5">Mission</Typography>
                <Typography>
                  To achieve our vision, we prioritize customer satisfaction,
                  foster collaborations, embrace sustainability, offer
                  competitive pricing, and leverage the power of Artificial
                  Intelligence and professional expertise.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default AboutUs;
