import React from "react";
import { useHistory, useLocation } from "react-router-dom";

//MUI
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import SectionScrolling from "../../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import CustomThreeDCarousel from "../../Component/HelperComponents/CustomCarousel/CustomThreeDCarousel";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";
import RangeSet from "../../Component/HelperComponents/RangeType/RangeSet";

//Image and Icons
import { kanist, anyonya, uttama, dot } from "../../Assets/icons/icons";
import BannerImage from "../../Assets/Rectangle93.jpg";
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";
//import BgConsultationFormSection from "../../Assets/bg-image/kitchen2.jpg";
import Dummy from "../../Assets/dummy.jpg";
//Data
import {
  bannerDetails,
  kitchenLayout,
  carouselMainSettings,
  ReasonsAmbienceKitchen,
  kitchenMaterials,
  ShutterFinishes,
  Handles,
  CountertopsBacksplash,
  PrallexDetails,
  Hardware,
  Appliances,
  SinkFaucet,
  DesignApproach,
  consultationFormSectionDetails,
} from "../../Data/ModularInteriors/Kitchens";
import theme from "../../Theme";

function Kitchens() {
  //Form Style
  const classes = FromFieldStyles();
  const location = useLocation();

  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          type={bannerDetails.type}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.image}
          bannertext={bannerDetails.bannertext}
        />

        <Section bgcolor="white">
          <RangeSet
            link={location.pathname}
            title={bannerDetails.title}
            desc={bannerDetails.desc}
          />
        </Section>
        <Section bgimg={BgImage}>
          <SectionTitle
            title="The reasons to choose a Ambience Kitchen"
            align="center"
            variant="h3"
          />
          <CustomThreeDCarousel data={ReasonsAmbienceKitchen} />
        </Section>

        <Section bgcolor="255, 255,255" padding="100px 0 100px 24px">
          <SectionTitle
            title="Familiarize yourself with your kitchen layout"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "480px" }}>
            Gain a comprehensive understanding of kitchen layouts, as it serves
            as the initial step towards designing the perfect kitchen.
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={kitchenLayout}
            showbutton={true}
          />
          {/*kitchenMaterials */}
          <SectionTitle title="Key Module Materials" variant="h5" />
          <Typography sx={{ maxWidth: "480px" }}>
            Endure a lifetime with premium core materials, as your kitchen with
            stands the test of time
          </Typography>
          <CustomCarouselMain
            type="imagetitlecont"
            data={kitchenMaterials}
            showbutton={true}
            showdesc={true}
          />
          {/*Captivating Aesthetics */}
          <SectionTitle
            title="Enhance Elegance With Captivating Aesthetics"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "480px" }}>
            Personalize your kitchen's appearance to match your preferences.
            Select from a variety of finishes, handles, and backsplashes for a
            unique touch.
          </Typography>
          <SectionTitle title="Shutter Finishes" variant="h6" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={ShutterFinishes}
            showbutton={true}
            showdesc={true}
          />
          <SectionTitle title="Handles" variant="h6" />
          <CustomCarouselMain
            type="imagetitle"
            data={Handles}
            showbutton={true}
          />
          <SectionTitle title="Countertops and Backsplash" variant="h6" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={CountertopsBacksplash}
            showbutton={true}
            showdesc={true}
          />
        </Section>

        <SectionScrolling
          title={PrallexDetails.title}
          highlight={PrallexDetails.highlight}
          bgimg={PrallexDetails.image}
        />

        <Section bgcolor="255, 255,255" padding="100px 0 100px 24px">
          <SectionTitle
            title="Enhance Elegance With Captivating Aesthetics."
            variant="h3"
          />

          <Typography sx={{ maxWidth: "480px" }}>
            Personalize your kitchen's appearance to match your preferences.
            Select from a variety of finishes, handles, and backsplashes for a
            unique touch.
          </Typography>
          <SectionTitle title="Cabinet and Hardware" variant="h5" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={Hardware}
            showbutton={true}
            showdesc={true}
          />
          <SectionTitle title="Appliances" variant="h5" />
          <CustomCarouselMain
            type="imagetitle"
            data={Appliances}
            showbutton={true}
          />
          <SectionTitle title="Sink and Faucet" variant="h5" />
          <CustomCarouselMain
            type="imagetitle"
            data={SinkFaucet}
            showbutton={true}
          />
        </Section>

        <Section bgimg={BgImage}>
          <SectionTitle title="Unravel the inner workings of your kitchen" />
        </Section>
        <Section bgcolor="255,255,255">
          <SectionTitle title="The 5 kitchen zones" />
          <Typography sx={{ maxWidth: "780px" }}>
            Efficiently designed to streamline your cooking process, our kitchen
            plans ensure that task-related items are strategically positioned
            within their respective zones, minimizing unnecessary footsteps.
          </Typography>
        </Section>
        <Section bgcolor="255,255,255" padding="100px 0 100px 24px">
          <SectionTitle
            title="Functional and Sophisticated Kitchen Design"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "780px" }}>
            Experience a seamless and stress-free kitchen journey with the
            kitchen triangle, enabling effortless movement between cooking,
            cleaning, and storage zones.
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={DesignApproach}
            showbutton={true}
          />
        </Section>
        <Section bgimg={BgImage} padding="100px 0 100px 24px">
          <Container>
            <Grid container sx={{ gap: "40px", marginBottom: "20px" }}>
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "300px 0px 0px 0px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#B29054",
                      padding: "7px 14px 7px 7px",
                      borderRadius: "40px",
                      color: `rgba(${theme.palette.text.white},1)`,
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <SvgIcon component={dot} />
                    Some text Some text
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0px 0px 50px 0px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "20px",
                      right: "-50px",
                      backgroundColor: "#A72024",
                      padding: "7px 14px 7px 7px",
                      borderRadius: "40px",
                      color: `rgba(${theme.palette.text.white},1)`,
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <SvgIcon component={dot} />
                    Some text
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                gap: "40px",
                margin: { xs: "0px 10px", sm: "0px 0px", md: "0px 00px" },
              }}
            >
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0px 0px 0px 220px",
                  }}
                ></Box>
              </Grid>
              <Grid sx={{ flex: 1 }}>
                <Typography variant="h3" sx={{ maxWidth: "480px" }}>
                  Crafted with your comfort in mind.
                </Typography>
                <Typography sx={{ maxWidth: "670px" }}>
                  Bid farewell to back pains with our ergonomically designed
                  kitchens, featuring cabinets positioned at your ideal height
                  for optimal comfort.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Section>
        <Section
          bgimg={consultationFormSectionDetails.image}
          bgcolor={consultationFormSectionDetails.bgcolor}
        >
          <Container>
            <ConsultationFormSection
              title={consultationFormSectionDetails.title}
            />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
}
export default Kitchens;
