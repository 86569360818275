import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import { kanist, anyonya, uttama } from "../../Assets/icons/icons";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/studyarea/Sa01.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Invite a friend and reap the rewards",
  desc: "Be a Part of Ambiance Decorio",
  bannerimage: Slide01,
};

const howItWorks = [
  {
    id: 1,
    icon: kanist,
    col: "black",
    col2: "#FECE78",
    title: "Refer",
    desc: "Spread the word about Ambiance Decorio to your friends and family and share their contact details with our team to provide a referral.",
  },
  {
    id: 2,
    icon: kanist,
    col: "black",
    col2: "#FFB97E",
    title: "Relax",
    desc: "Unlock the potential to earn rewards when the person you've referred books Modular interiors with Ambiance Decorio.",
  },
  {
    id: 3,
    icon: kanist,
    col: "black",
    col2: "#FF7C7C",
    title: "Rejoice ",
    desc: "Earn a generous commission on the total project value as soon as the person you've referred pays 50% of the total amount for their project with Ambiance Decorio.",
  },
];

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export { bannerDetails, howItWorks, consultationFormSectionDetails };
