import React from "react";
import Slide01 from "../../Assets/dummy.jpg";
import Slide02 from "../../Assets/dummy2.jpg";

const bannerDetails = [
  {
    id: 1,
    imageUrl: Slide01,
    title: "At Hassle-Free Commercial Inferiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "236,216,206,1",
  },
  {
    id: 2,
    imageUrl: Slide02,
    title: "At Hassle-Free Commercial Inferiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "205,251,117,1",
  },
];

const sectorDetails = [
  {
    id: 1,
    imageUrl: Slide01,
    title: "Offices",
    link: "#",
  },
  {
    id: 2,
    imageUrl: Slide01,
    title: "Retails",
    link: "#",
  },
];

const sectorDetailsSettings = {
  items: 2,
  margin: 60,
  autoplay: false,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
  },
};

const OfficesDetails = [
  {
    id: 1,
    title: "Offices 01",
    imageUrl: Slide01,
    link: "/commercial/offices",
  },
  {
    id: 2,
    title: "Offices 02",
    imageUrl: Slide01,
    link: "/commercial/offices",
  },
  {
    id: 3,
    title: "Offices 03",
    imageUrl: Slide01,
    link: "/commercial/offices",
  },
  {
    id: 4,
    title: "Offices 04",
    imageUrl: Slide01,
    link: "/commercial/offices",
  },
  {
    id: 5,
    title: "Offices 05",
    imageUrl: Slide01,
    link: "/commercial/offices",
  },
];
const RetailsDetails = [
  {
    id: 1,
    title: "Retails 01",
    imageUrl: Slide01,
    link: "/commercial/retails",
  },
  {
    id: 2,
    title: "Retails 02",
    imageUrl: Slide01,
    link: "/commercial/retails",
  },
  {
    id: 3,
    title: "Retails 03",
    imageUrl: Slide01,
    link: "/commercial/retails",
  },
  {
    id: 4,
    title: "Retails 04",
    imageUrl: Slide01,
    link: "/commercial/retails",
  },
  {
    id: 5,
    title: "Retails 05",
    imageUrl: Slide01,
    link: "/commercial/retails",
  },
];

export {
  bannerDetails,
  sectorDetails,
  sectorDetailsSettings,
  OfficesDetails,
  RetailsDetails,
};
