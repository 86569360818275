import React, { useState } from "react";
import { Link } from "react-router-dom";

//MUI
import { Box, SvgIcon, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Image and Icon
import { sofa, chair } from "../../Assets/icons/icons";

const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiBackdrop-root": {
      // Customize backdrop styles here
      backgroundColor: "transparent", // Make the backdrop transparent
    },
  },
}));

const FixedMenu = () => {
  const classes = useStyles();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleOpenMenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleOpenMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          zIndex: "7",
          top: 150,
          right: 0,
        }}
      >
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            "& li": {
              listStyleType: "none",

              "& div": {
                position: "relative",

                backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                gap: "10px",
                color: "white",
                textDecoration: "none",
                borderRadius: "50px 0 0 50px",
                right: "calc(-100% + 45px)",
                transition: "all  ease 1s",
                cursor: "initial",
                "&:hover": {
                  transition: "all  ease 1s",
                  right: "calc(-100% + 100%)",
                },
              },
            },
          }}
        >
          <li>
            <Box
              aria-controls="dropdown-menu-1"
              aria-haspopup="true"
              onClick={handleOpenMenu1}
            >
              <SvgIcon
                component={sofa}
                className="svgIcon"
                sx={{
                  fill: "#fff",
                }}
              />
              <span>Residence</span>
            </Box>
            <Menu
              id="dropdown-menu-1"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleCloseMenu}
              className={classes.menu}
            >
              <MenuItem onClick={handleCloseMenu} component={Link} to="/login">
                Option 1.1
              </MenuItem>
              <MenuItem onClick={handleCloseMenu}>Option 1.2</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Option 1.3</MenuItem>
            </Menu>
          </li>
          <li>
            <Box
              aria-controls="dropdown-menu-2"
              aria-haspopup="true"
              onClick={handleOpenMenu2}
            >
              <SvgIcon
                component={chair}
                className="svgIcon"
                sx={{
                  fill: "#fff",
                }}
              />
              Commercial
            </Box>
            <Menu
              id="dropdown-menu-2"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleCloseMenu}
              className={classes.menu}
            >
              <MenuItem onClick={handleCloseMenu}>Option 2.1</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Option 2.2</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Option 2.3</MenuItem>
            </Menu>
          </li>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FixedMenu;
