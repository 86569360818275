import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import BannerImage from "../../Assets/images/fullhomeinteriors/Fhi01.jpg";

const bannerDetails = {
  title: "Embark on a Holistic Home Interiors Journey.",
  desc: "Turn your interior dreams into reality with Ambience Decorio. Our platform brings together award-winning designers, service partners, and renowned brands, ensuring a seamless transformation of your home interiors.",
  bannerimage: BannerImage,
};

const carouselSettings = {
  responsive: {
    0: {
      items: 1.3,
    },

    600: {
      items: 1.6,
    },
    900: {
      items: 2.8,
    },
    1200: {
      items: 3.3,
    },
    1536: {
      items: 4.5,
    },
  },
};

const whatWeOffer = [
  {
    id: 1,
    title: "Home Planning",
    imageUrl: Slide01,
    category: "Home Planning",
    // details: {
    //   layout: "Parallel-Shaped Kitchen Design",
    //   room_dimension: "7x7 feet",
    //   style: "Contemporary",
    // },
    link: "/full-home-interiors/home-planning",
  },
  {
    id: 2,
    title: "Home Design",
    imageUrl: Slide01,
    category: "Home Design",

    link: "/full-home-interiors/home-design",
  },
  {
    id: 3,
    title: "Project Management",
    imageUrl: Slide01,
    category: "Project Management",

    link: "/full-home-interiors/project-management",
  },
];

export { bannerDetails, whatWeOffer, carouselSettings };
