import React from "react";
//MUI
import theme from "../../Theme";
import { Box, Typography } from "@mui/material";

const LineHeader = ({ title, title2, type }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          "&::before": {
            content: "''",
            borderTop: "3px solid",
            borderColor:
              type === "dark"
                ? `rgba(${theme.palette.primary.default},1)`
                : `rgba(${theme.palette.text.offtheme},1)`,
            width: "60px",
            marginTop: "13px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color:
              type === "dark"
                ? `rgba(${theme.palette.text.default},1)`
                : `rgba(${theme.palette.text.white},1)`,
            "& span": {
              color:
                type === "dark"
                  ? `rgba(${theme.palette.primary.default},1)`
                  : `rgba(${theme.palette.text.offtheme},1)`,
            },
            textWrap: "balance",
          }}
        >
          {title} <span>{title2}</span>
        </Typography>
      </Box>
    </React.Fragment>
  );
};

LineHeader.defaultProps = {
  title: "hi",
  title2: "hello",
  type: "dark",
};
export default LineHeader;
