import React, { useState } from "react";
import { useParams } from "react-router-dom";
//MUI
import theme from "../../Theme";
import { Box, Container, Grid, Typography, Button, Modal } from "@mui/material";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ModalSection from "../../Component/HelperComponents/ModalSection";
import Faqs from "../../Component/HelperComponents/Faqs/Faqs";

//images
import Dummy from "../../Assets/images/kitchen/K1.jpg";

//Data

import {
  BannerDetails as BannerDetailsModularKitchenDesigns,
  Gallery as galleryModularKitchenDesigns,
} from "../../Data/DesignIdeas/ModularKitchenDesigns";
import {
  BannerDetails as BannerDetailsWardrobeDesigns,
  Gallery as galleryWardrobeDesigns,
} from "../../Data/DesignIdeas/WardrobeDesigns";
import {
  BannerDetails as BannerDetailsMasterBedroomDesigns,
  Gallery as galleryMasterBedroomDesigns,
} from "../../Data/DesignIdeas/MasterBedroomDesigns";
import {
  BannerDetails as BannerDetailsLivingRoomDesigns,
  Gallery as galleryLivingRoomDesigns,
} from "../../Data/DesignIdeas/LivingRoomDesigns";
import {
  BannerDetails as BannerDetailsPoojaRoomDesigns,
  Gallery as galleryPoojaRoomDesigns,
} from "../../Data/DesignIdeas/PoojaRoomDesigns";
import {
  BannerDetails as BannerDetailsTvUnitDesigns,
  Gallery as galleryTvUnitDesigns,
} from "../../Data/DesignIdeas/TvUnitDesigns";
import {
  BannerDetails as BannerDetailsKidsBedroomDesigns,
  Gallery as galleryKidsBedroomDesigns,
} from "../../Data/DesignIdeas/KidsBedroomDesigns";
import {
  BannerDetails as BannerDetailsDiningRoomDesigns,
  Gallery as galleryDiningRoomDesigns,
} from "../../Data/DesignIdeas/DiningRoomDesigns";
import {
  BannerDetails as BannerDetailsGuestBedroomDesigns,
  Gallery as galleryGuestBedroomDesigns,
} from "../../Data/DesignIdeas/GuestBedroomDesigns";
import {
  BannerDetails as BannerDetailsWorkstations,
  Gallery as galleryWorkstations,
} from "../../Data/DesignIdeas/Workstations";
import {
  BannerDetails as BannerDetailsOfficeTables,
  Gallery as galleryOfficeTables,
} from "../../Data/DesignIdeas/OfficeTables";
import {
  BannerDetails as BannerDetailsStorageUnits,
  Gallery as galleryStorageUnits,
} from "../../Data/DesignIdeas/StorageUnits";

const DesignIdeasPage = () => {
  const { innerPages } = useParams();

  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  //Data
  // Determine the data based on the inner page
  let BannerDetailsData;
  let galleryData;

  if (innerPages === "modular-kitchen-designs") {
    BannerDetailsData = BannerDetailsModularKitchenDesigns;
    galleryData = galleryModularKitchenDesigns;
  } else if (innerPages === "wardrobe-designs") {
    BannerDetailsData = BannerDetailsWardrobeDesigns;
    galleryData = galleryWardrobeDesigns;
  } else if (innerPages === "master-bedroom-designs") {
    BannerDetailsData = BannerDetailsMasterBedroomDesigns;
    galleryData = galleryMasterBedroomDesigns;
  } else if (innerPages === "living-room-designs") {
    BannerDetailsData = BannerDetailsLivingRoomDesigns;
    galleryData = galleryLivingRoomDesigns;
  } else if (innerPages === "pooja-room-designs") {
    BannerDetailsData = BannerDetailsPoojaRoomDesigns;
    galleryData = galleryPoojaRoomDesigns;
  } else if (innerPages === "tv-unit-designs") {
    BannerDetailsData = BannerDetailsTvUnitDesigns;
    galleryData = galleryTvUnitDesigns;
  } else if (innerPages === "kids-bedroom-designs") {
    BannerDetailsData = BannerDetailsKidsBedroomDesigns;
    galleryData = galleryKidsBedroomDesigns;
  } else if (innerPages === "dining-room-designs") {
    BannerDetailsData = BannerDetailsDiningRoomDesigns;
    galleryData = galleryDiningRoomDesigns;
  } else if (innerPages === "guest-bedroom-designs") {
    BannerDetailsData = BannerDetailsGuestBedroomDesigns;
    galleryData = galleryGuestBedroomDesigns;
  } else if (innerPages === "workstations") {
    BannerDetailsData = BannerDetailsWorkstations;
    galleryData = galleryWorkstations;
  } else if (innerPages === "office-tables") {
    BannerDetailsData = BannerDetailsOfficeTables;
    galleryData = galleryOfficeTables;
  } else if (innerPages === "storage-units") {
    BannerDetailsData = BannerDetailsStorageUnits;
    galleryData = galleryStorageUnits;
  }

  function formatString(inputString) {
    // Split the input string into an array of words using hyphens as separators
    const words = inputString.split("-");

    // Capitalize the first letter of each word and join them with spaces
    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  }

  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          type={BannerDetailsData.type}
          bannertext={BannerDetailsData.bannertext}
          image={BannerDetailsData.image}
          backgroundPosition={BannerDetailsData.backgroundPosition}
        />
        <Section bgcolor="white">
          <SectionTitle title={formatString(innerPages)} />
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography sx={{ marginBottom: "20px" }}>
                Kitchens are the heart of any home, and they should be designed
                with both functionality and style in mind. A well-designed
                kitchen can make all the difference in how a home feels and
                functions, which is why it's so important to take the time to
                plan the perfect kitchen for your needs.
              </Typography>
              <Typography>
                We create straightforward modular kitchen designs that
                seamlessly combine functionality and style. Simple kitchens with
                minimalist interiors to more elaborately designed kitchen
                interiors are all included in our carefully picked, modular
                kitchen designs in India. All of our contemporary kitchen
                designs may be altered to match your needs and available space.
                We also have kitchen interior designs that accommodate various
                shapes, whether they be L-shaped, -shaped, or open. Our goal is
                to create a kitchen interior design that complements your home
                to the fullest. Check out our best kitchen ideas for 2023 to
                start changing your area right away.
              </Typography>
            </Grid>
          </Grid>
        </Section>

        {galleryData ? (
          <Section bgcolor="white" padding="0 24px 50px 24px">
            <SectionTitle title={formatString(innerPages)} shadowtext="Idea" />
            <CustomGallery
              data={galleryData}
              type="slideimage"
              handleClickOpen={handleClickOpen}
              loadmore={true}
            />
          </Section>
        ) : null}
        <Section bgcolor="white">
          <Container>
            <SectionTitle
              title="Frequently Asked Questions"
              shadowtext="FAQ's"
            />

            <Faqs />
          </Container>
        </Section>
      </DefaultLayout>

      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm />
      </ModalSection>
    </React.Fragment>
  );
};

export default DesignIdeasPage;
