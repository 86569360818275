import React from "react";
//MUI
import theme from "../../Theme";
import {
  Box,
  Container,
  Grid,
  Button,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const ModalSection = (props) => {
  const { children, ...rest } = props;
  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 20px)", //(32+32) 64px is the padding
            maxWidth: "450px",
            border: "none",
            backgroundColor: `rgba(${theme.palette.text.default},.9)`,
            boxShadow: 24,
            boxSizing: "border-box",

            p: 4,
            "&:focus": {
              outline: "none", // Remove focus outline from the modal
            },
          }}
        >
          <IconButton
            sx={{
              color: `rgba(${theme.palette.text.white},.9)`,
              position: "absolute",
              top: "-15px",
              right: "-15px",
              fontSize: "10px",
              //backgroundColor: `rgba(${theme.palette.primary.default},.9)`,
              display: "flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",

              borderRadius: 0,
            }}
            onClick={props.onClose}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>

          {children}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

ModalSection.defaultProps = {
  margin: "auto",
};

export default ModalSection;
