import React from "react";
import { Link } from "react-router-dom";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import theme from "../../Theme";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  SvgIcon,
  Button,
  TextField,
  Select,
  InputAdornment,
  Switch,
  FormControl,
  InputLabel,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
//Custome Components
import Dummy from "../../Assets/dummy.jpg";
import { indflag, Creativity, chair } from "../../Assets/icons/icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import MailIcon from "@mui/icons-material/Mail";

const useStyles = makeStyles({
  boxSet: {
    display: "flex",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    //transition: "all  ease 1s",
    "& div": {
      " & a ": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        color: `rgba(${theme.palette.text.default},1)`,
        width: "100px",
        height: "100px",
        borderRadius: "80px",
        transition: "all  ease 1s",
      },
      "&:hover": {
        " & a ": {
          transition: "all  ease 1s",
          color: `rgba(${theme.palette.primary.default},1)`,
          backgroundColor: `rgba(${theme.palette.text.default},.1)`,
        },
      },
    },
  },
});

const ContactUs = () => {
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  //Custom Styles
  const classes = useStyles(); //Custom Style

  return (
    <React.Fragment>
      <DefaultLayout>
        <Section>
          <Container maxWidth={"xl"} sx={{}}>
            <Typography variant="h5">Get in Touch</Typography>

            <Grid container sx={{ marginTop: "70px" }}>
              <Grid item xs={12} md={6}>
                <img
                  src={Dummy}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  sx={{
                    backgroundColor: `rgba(${theme.palette.text.default},.02)`,
                    padding: "60px",
                    boxSizing: "border-box",
                  }}
                >
                  <Grid item>
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                component={indflag}
                                viewBox="0 0 30 23"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormControl>
                        <InputLabel id="select-label">Services</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          value={selectedOption}
                          label="Select an option"
                          onChange={handleOptionChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="option1">Option 1</MenuItem>
                          <MenuItem value="option2">Option 2</MenuItem>
                          <MenuItem value="option3">Option 3</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControlLabel
                        control={<Switch defaultChecked />}
                        label="Stay updated in Whatsapp"
                      />
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          color: `rgba(${theme.palette.text.white},1)`,
                          padding: "15px 40px",
                          boxSizing: "border-box",
                          cursor: "inherit",
                        }}
                      >
                        Get free consultation
                      </Button>
                      <Box>
                        <Typography
                          align="center"
                          sx={{
                            color: `rgba(${theme.palette.text.default},1)`,
                            letterSpacing: ".05em",
                            margin: "0px 20px",
                            "& a": {
                              color: `rgba(${theme.palette.primary.default},1)`,
                              padding: "0px 5px",
                              textDecoration: "none",
                              display: "inline-block",
                            },
                          }}
                        >
                          By submitting this form, you agree to the
                          <Box component={Link} to="#">
                            privacy policy
                          </Box>
                          and
                          <Box component={Link} to="#">
                            terms of use
                          </Box>
                        </Typography>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
                <Box className={classes.boxSet}>
                  <Box>
                    <Link to={"#"} style={{ textDecoration: "none" }}>
                      <LocationOnIcon />
                      Location
                    </Link>
                  </Box>

                  <Box>
                    <Link to={"#"} style={{ textDecoration: "none" }}>
                      <PhonelinkRingIcon />
                      Phone
                    </Link>
                  </Box>

                  <Box>
                    <Link to={"#"} style={{ textDecoration: "none" }}>
                      <MailIcon />
                      Mail
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default ContactUs;
