import React from "react";
import { Link } from "react-router-dom";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";

import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  SvgIcon,
} from "@mui/material";
import theme from "../../Theme";

//data
import { bannerDetails, howItWorks } from "../../Data/Others/ReferAFriend";
import { fontSize } from "@mui/system";

const ReferAFriend = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
          type={bannerDetails.type}
        />
        <Section bgcolor="1,1,0,0.02" margin={"40px 0"} padding={"70px 24px"}>
          <Container maxWidth={"xl"}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              spacing={5}
            >
              <Grid
                item
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
                xs={12}
                md={6}
              >
                <Typography
                  variant="h4"
                  sx={{ color: `rgba(${theme.palette.primary.default},1)` }}
                >
                  Unlock earnings upto ₹ 20,000 by recommending Ambience Decorio
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: "center" }} xs={12} md={6}>
                <Link to="/login">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      color: `rgba(${theme.palette.text.white},1)`,
                      padding: "15px 40px",
                      boxSizing: "border-box",
                      cursor: "inherit",
                    }}
                  >
                    Sign up Now
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Section>
        <Section bgcolor="255,255,255">
          <SectionTitle align="center" title="How it Works" />
          <Container maxWidth={"xl"}>
            <Grid container>
              {howItWorks.map((item, key) => (
                <Grid md="4" sx={{ textAlign: "center" }} key={key}>
                  <Box sx={{ padding: "50px" }}>
                    <SvgIcon
                      component={item.icon}
                      sx={{ fontSize: "120px" }}
                      col={item.col}
                      col2={item.col2}
                    />
                    <Typography variant="h6" sx={{ margin: "10px" }}>
                      {item.title}
                    </Typography>
                    <Typography>{item.desc}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default ReferAFriend;
