import React from "react";
//MUI
import theme from "../../../Theme";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  SvgIcon,
  Button,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
//Custome Components
import ConsultationForm from "./ConsultationForm";

const ConsultationFormSection = ({ title }) => {
  return (
    <React.Fragment>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item md={7}>
          <Typography
            variant="h3"
            sx={{
              color: `rgba(${theme.palette.text.white},1)`,
              textShadow: "2px 2px 5px #000000",
              marginRight: {
                xs: "0",
                sm: "120px",
              },
              marginBottom: {
                xs: "40px",
                sm: "0px",
              },
              textAlign: {
                xs: "center",
                sm: "inherit",
              },
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item md={5} sx={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: `rgba(${theme.palette.text.default},.9)`,
              width: "100%",
              padding: "60px 35px",
              boxSizing: "border-box",
              color: "`rgba(${theme.palette.text.white},1)`",
              borderRadius: "10px",
            }}
          >
            <ConsultationForm />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ConsultationFormSection;
