import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
//data
import { bannerDetails } from "../../Data/Others/PartnerWithUs";

//Image and Icons
import Dummy from "../../Assets/dummy.jpg";
import Section from "../../Component/HelperComponents/Section";
import {
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  Button,
} from "@mui/material";
import theme from "../../Theme";
import { Box } from "@mui/system";
const PartnerWithUs = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
          type={bannerDetails.type}
        />
        <Section bgcolor="255,255,255">
          <Container maxWidth={"xl"}>
            <Grid container alignItems={"center"} spacing={5}>
              <Grid item xs={12} md={6}>
                <img
                  src={Dummy}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h5">
                    Key Elements for Partnering with Us
                  </Typography>
                  <List>
                    <ListItem>
                      Become the master of your design studio, contracting firm,
                      or design & build studio.
                    </ListItem>
                    <ListItem>
                      Ignite innovation and flexibility in your design business.
                    </ListItem>
                    <ListItem>
                      Immerse yourself in our extensive 5+ years of interior
                      design industry expertise.
                    </ListItem>
                    <ListItem>
                      Discover a dedicated ensemble of passionate interior
                      designers and site supervisors.
                    </ListItem>
                    <ListItem></ListItem>
                    <ListItem></ListItem>
                  </List>

                  <Grid container alignItems={"center"} spacing={2}>
                    <Grid item xs={12} md={7}>
                      <Typography
                        variant="h6"
                        color={`rgba(${theme.palette.primary.default},1)`}
                      >
                        Resonate with our ethos?
                      </Typography>
                      <Typography>
                        Let's connect and embark on vour project today!
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          color: `rgba(${theme.palette.text.white},1)`,
                          padding: "15px 40px",
                          boxSizing: "border-box",
                          cursor: "inherit",
                        }}
                      >
                        Enquire Now
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default PartnerWithUs;
