import { createTheme } from "@mui/material/styles";
//Icons
import customCursor from "../src/Assets/icons/cursor.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF2E33",
      default: "239, 46, 51",
      contrastText: "255, 255, 255",
    },
    secondary: {
      main: "#0044FF",
      default: "0, 68, 255",
      light: "0, 102, 255",
      dark: "0, 57, 206",
      contrastText: "255, 255, 255",
    },

    text: {
      main: "#181818",
      primary: "#181818",
      default: "24, 24, 24",
      white: "255,255,255",
      offtheme: "254, 206,120",
      darktheme: "16, 26, 50",
    },
    gray: {
      main: "#D3DCDC",
      default: "211,220,220",
    },
  },
  typography: {
    fontFamily: "Raleway, sans-serif",
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: "90px",
      lineHeight: "140%",
      letterSpacing: "-0.01em",
    },
    h2: {
      fontWeight: 700,
      fontSize: "45px",
      lineHeight: "140%",
      letterSpacing: "-0.005em",
    },
    h3: {
      fontSize: "40px",
      lineHeight: "1.4",
      fontWeight: 700,
      textWarp: "balance",
      "@media (max-width:600px)": {
        fontSize: "30px",
        lineHeight: "1.4",
        textWarp: "balance",
      },
    },
    h4: {
      fontSize: "36px",
      lineHeight: "1.4",
      fontWeight: 500,
    },
    h5: {
      fontSize: "36px",
      lineHeight: "1.4",
      fontWeight: 700,
      letterSpacing: "0.02em",
      "@media (max-width:600px)": {
        fontSize: "26px",
        lineHeight: "1.4",
        textWarp: "balance",
      },
    },
    h6: {
      fontSize: "22px",
      lineHeight: "1.4",
      fontWeight: 600,
      letterSpacing: "0.02em",
    },
    subtitle1: {
      fontSize: "20px",
      lineHeight: "1.6",
      fontWeight: 400,
      letterSpacing: "0.03em",
    },

    //Check below

    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: "0.04em",
    },
    body2: {},
    body3: {
      fontSize: "36px",
      fontWeight: 700,
      lineHeight: "50px",
      letterSpacing: "0em",
    },
    small: {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      letterSpacing: "0em",
    },
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Customize the backdrop color here
        },
      },
    },
  },
});

export default theme;
