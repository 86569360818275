import React from "react";

import Slide01 from "../../Assets/dummy.jpg";

import BannerImage from "../../Assets/images/modularinteriors/modularinterior.jpg";
import ModularKitchen from "../../Assets/images/kitchen/K1.jpg";
import Wardrobes from "../../Assets/images/wardrobe/Wd01.jpeg";
import PoojaMandir from "../../Assets/images/pooja-room/Pr01.jpg";
import StudyArea from "../../Assets/images/studyarea/Sa01.jpg";
import TvUnits from "../../Assets/images/tv-units/Tvu04.jpg";
import CrockeryUnits from "../../Assets/images/crockeryunits/Cu01.jpg";

const bannerDetails = {
  title: "Elevate your home with enduring, exquisite woodwork.",
  desc: "Experience seamless home transformation with Ambience Decorio Select. Explore our extensive collection of affordable, top-notch modular solutions including kitchens, wardrobes, TV units, bookshelves, shoe racks, and more. Tailor-made to suit your home, choose from a variety of colors, designs, and sizes that perfectly complement your unique style.",
  bannerimage: BannerImage,
};

const carouselSettings = {
  responsive: {
    0: {
      items: 1.3,
    },

    600: {
      items: 1.6,
    },
    900: {
      items: 2.8,
    },
    1200: {
      items: 3.3,
    },
    1536: {
      items: 4.5,
    },
  },
};

const whatWeOffer = [
  {
    id: 1,
    title: "Kitchen",
    imageUrl: ModularKitchen,
    category: "Modular Kitchen",
    link: "/modular-interiors/kitchens",
  },
  {
    id: 2,
    title: "Wardrobes",
    imageUrl: Wardrobes,
    category: "Wardrobes",
    link: "/modular-interiors/wardrobes",
  },
  {
    id: 3,
    title: "Pooja Mandir",
    imageUrl: PoojaMandir,
    category: "Pooja Mandir ",
    link: "/modular-interiors/pooja-mandir",
  },
  {
    id: 4,
    title: "Study Area",
    imageUrl: StudyArea,
    category: "Study Area",
    link: "/modular-interiors/study-area",
  },
  {
    id: 5,
    title: "Crockery Units",
    imageUrl: CrockeryUnits,
    category: "Crockery Units",
    link: "/modular-interiors/crockery-units",
  },
  {
    id: 6,
    title: "TV Units",
    imageUrl: TvUnits,
    category: "TV Units",
    // details: {
    //   layout: "Parallel-Shaped Kitchen Design",
    //   room_dimension: "7x7 feet",
    //   style: "Contemporary",
    // },
    link: "/modular-interiors/tv-units",
  },
];

export { bannerDetails, carouselSettings, whatWeOffer };
