import React from "react";
//MUI
import theme from "../../Theme";
import { Typography } from "@mui/material";

const SectionTitle = (props) => {
  return (
    <Typography
      variant={props.variant}
      sx={{
        position: "relative",
        lineHeight: "2",
        overflow: "clip",
        textAlign: props.align,
        margin: props.margin,
        "&::after": {
          content: `"${props.shadowtext}"`,
          position: "absolute",
          color: `rgba(${theme.palette.text.default},.05)`,
          pointerEvents: "none",
          whiteSpace: "nowrap",
          fontSize: "80px",
          fontWeight: "700",
          zIndex: 1,
          bottom: "-30px",
          left: props.align === "center" ? "50%;" : "-5px;",
          transform:
            props.align === "center" ? "translateX(-50%)" : "translateX(0)",
        },
      }}
    >
      {props.title}
    </Typography>
  );
};

SectionTitle.defaultProps = {
  variant: "h3",
  align: "left",
  shadowtext: "",
  margin: "0 0 0 0",
};

export default SectionTitle;
