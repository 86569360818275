import React, { useState } from "react";

import { Box, Typography, Stack, List, ListItem, Button } from "@mui/material";
import theme from "../../../Theme";

import Logo from "../../../Assets/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import ShareIcon from "@mui/icons-material/Share";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          width: sidebarOpen ? "250px" : "60px", // Adjust width based on sidebarOpen state

          padding: "20px",
          height: "100%",
          backgroundColor: `rgba(${theme.palette.text.darktheme},1)`,
          color: `rgba(${theme.palette.text.white},1)`,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
          transition: "all  ease 1s",
          "& a": {
            color: "white",
            textDecoration: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img src={Logo} style={{ filter: "brightness(0) invert(1)" }} />
            <Button onClick={handleSidebarToggle}>Toggle</Button>
          </Box>

          <Box className="links">
            <List>
              <ListItem>
                <Link to={"/user/enquiries"}>Enquiries</Link>
              </ListItem>
              <ListItem>
                <Link to={"/user/refer-a-friend"}>Refer a Friend</Link>
              </ListItem>
              <ListItem>
                <Link to={"/user/profile"}>Profile</Link>
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box>
          <Link to={"/login"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <LogoutIcon />
              <Typography variant="body1">Log Out</Typography>
            </Stack>
          </Link>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Sidebar;
