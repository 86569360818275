import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../../../Theme";
import {
  Box,
  Typography,
  List,
  ListItem,
  SvgIcon,
  Button,
  Rating,
  Avatar,
  CardHeader,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  linkWrapper: {
    color: `rgba(${theme.palette.text.default},1)`,
    textDecoration: "none",
    display: "block",
    height: "100%",
  },
  imgContentDivWrapper: {
    marginBottom: "40px",
    height: "calc(100% - 40px)",
    backgroundColor: `rgba(${theme.palette.text.white},.8)`,
    "&:hover $imgDiv": { transform: "scale(1.2) rotate(3deg)" },
  },
  imgDivWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  imgDiv: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover", // or 'contain' for different scaling behavior
    backgroundPosition: "center center",
    transition: "all ease 1s",
  },
  titleOver: {
    position: "absolute",
    bottom: "40px",
    padding: "10px 30px 10px 10px",
    backgroundColor: `rgba(${theme.palette.text.white},.8)`,
    borderRadius: "0 5px 5px 0",
    color: `rgba(${theme.palette.text.default},1)`,
  },
  contentDivWrapper: {
    padding: "20px",

    boxSizing: "border-box",
  },
  StyledList: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginBottom: "40px",
    "& li": {
      listStyleType: "none",

      "& span, a": {
        display: "block",
        fontSize: "14px",
        listStyleType: "none",
        padding: "10px 25px",
        backgroundColor: "#D3DCDC",
        borderRadius: "50px",
        color: `rgba(${theme.palette.text.default},1)`,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      },
      "& a": {
        textDecoration: "none",
        backgroundColor: "#A8BFBF",
        transition: "all ease 1s",
        "&:hover": {
          backgroundColor: "#D3DCDC",
        },
      },
    },
  },
});

const LayoutImageTitleCont = ({
  link,
  image,
  desc,
  title,
  showdesc,
  details = {},
}) => {
  const classes = useStyles(); //Custom Style
  // Dynamically render all key-value pairs of the `details` object
  const renderDetails = () => {
    return Object.entries(details).map(([key, value]) => (
      <ListItem
        key={key}
        sx={{
          padding: "0px 0 5px 0",
          "& p": {
            fontSize: "14px",
            "& span": {
              fontWeight: 600,
            },
          },
        }}
      >
        <Typography>
          <span>{`${key}`} :</span> {`${value}`}
        </Typography>
      </ListItem>
    ));
  };
  // Dynamically render all key-value pairs of the `details` object End
  return (
    <React.Fragment>
      <React.Fragment>
        <Box
          component={link ? Link : "div"}
          to={link}
          className={classes.linkWrapper}
        >
          <Box
            className={classes.imgContentDivWrapper}
            sx={{
              boxShadow:
                "0px 8px 16px -8px rgba(0, 0, 0, 0.3), 0px 17px 27px -6px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Box
              className={classes.imgDivWrapper}
              sx={{
                aspectRatio: "1/.75",
                margin: "0px",
                width: "100%",
              }}
            >
              <Box
                className={classes.imgDiv}
                sx={{
                  backgroundImage: `url(${image})`,
                }}
              ></Box>
            </Box>

            <Box className={classes.contentDivWrapper}>
              <Typography variant="h6">{title}</Typography>
              {showdesc ? (
                <Typography sx={{ fontSize: "13px", marginTop: "5px" }}>
                  {desc}
                </Typography>
              ) : null}
              {Object.keys(details).length > 0 ? (
                <List>{renderDetails()}</List>
              ) : null}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
};

export default LayoutImageTitleCont;
