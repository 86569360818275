import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import { Grid, Typography } from "@mui/material";

const Policies = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        {/* <InnerBannner type="fullimage" bannertext={false} /> */}
        <Section bgcolor="white">
          <SectionTitle title={"Privacy Policy"} />
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography sx={{ marginBottom: "20px" }}>
                Effective Date: August 01, 2023
              </Typography>
              <Typography sx={{ marginBottom: "20px" }}>
                [AMBIANCE DECORIO], a modular interior company ("we" or "us"),
                is committed to protecting the privacy and security of your
                personal information. This Privacy Policy describes how we
                collect, use, disclose, and protect your personal information
                when you visit our website https://ambiencedecorio.com (the
                "Website") or interact with our services. By accessing or using
                our Website, you consent to the collection, use, and disclosure
                of your personal information as described in this Privacy
                Policy.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                1. Information We Collect
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                1.1 Personal Information
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                We may collect personal information from you when you
                voluntarily provide it to us, such as when you fill out forms on
                our Website, submit inquiries or requests, or communicate with
                us via email or other channels. This may include your name,
                email address, phone number, postal address, and any other
                information you choose to provide.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                1.2 Automatically Collected Information
              </Typography>
              <Typography sx={{ marginBottom: "20px", paddingLeft: "65px" }}>
                When you visit our Website, we may automatically collect certain
                information about your device, browsing actions, and patterns.
                This may include your IP address, browser type, operating
                system, referring URLs, and other technical information. We may
                also collect information about your usage of our Website, such
                as the pages you visit, the links you click on, and the duration
                of your visit.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                2. Use of Information
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                2.1 Providing Services
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                We may use the personal information we collect to provide you
                with the requested services, including designing, planning, and
                delivering modular interior solutions. This may involve
                contacting you, responding to your inquiries, and fulfilling
                your requests.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                2.2 Communication
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                We may use your personal information to communicate with you,
                including sending you updates, notifications, newsletters,
                promotional materials, and other information related to our
                services. You have the option to opt out of receiving such
                communications.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                2.3 Improving Our Services
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                We may use the information we collect to analyze and improve our
                Website, services, and customer experience. This includes
                monitoring and analyzing trends, conducting research, and
                gathering demographic information.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                2.4 Legal Compliance
              </Typography>
              <Typography sx={{ marginBottom: "20px", paddingLeft: "65px" }}>
                We may use or disclose your personal information to comply with
                applicable laws, regulations, legal processes, or governmental
                requests. We may also use or disclose your information to
                enforce our terms and conditions, protect our rights, privacy,
                safety, or property, or the rights, privacy, safety, or property
                of our users or others.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                3. Sharing of Information
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                3.1 Service Providers
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                We may share your personal information with trusted third-party
                service providers who assist us in operating our business,
                providing services, or conducting activities on our behalf.
                These service providers are contractually bound to handle your
                information securely and only use it for the purposes specified
                by us.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                3.2 Business Transfers
              </Typography>
              <Typography sx={{ marginBottom: "10px", paddingLeft: "65px" }}>
                In the event of a merger, acquisition, sale, or transfer of
                assets, your personal information may be transferred to the
                acquiring entity or third party involved. We will take
                reasonable steps to ensure the confidentiality of your personal
                information is maintained throughout such transactions.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "35px",
                }}
              >
                3.3 Legal Requirements
              </Typography>
              <Typography sx={{ marginBottom: "20px", paddingLeft: "65px" }}>
                We may disclose your personal information if required to do so
                by law or in response to valid legal requests, such as
                subpoenas, court orders, or government investigations.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                4. Data Security
              </Typography>

              <Typography sx={{ marginBottom: "20px", paddingLeft: "35px" }}>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, or destruction. However, please note that no method
                of transmission over the internet or electronic storage is
                completely secure, and we cannot guarantee absolute security of
                your data.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                5. Your Choices and Rights
              </Typography>

              <Typography sx={{ marginBottom: "20px", paddingLeft: "35px" }}>
                You have certain rights regarding your personal information,
                including the right to access, update, correct, and delete your
                information. You may also have the right to object to or
                restrict certain processing of your personal information. To
                exercise these rights, please contact us using the information
                provided below.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                6. Children's Privacy
              </Typography>

              <Typography sx={{ marginBottom: "20px", paddingLeft: "35px" }}>
                Our services are not directed to individuals under the age of
                13. We do not knowingly collect personal information from
                children. If you believe we have unintentionally collected
                personal information from a child, please contact us
                immediately.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                7. Changes to the Privacy Policy
              </Typography>

              <Typography sx={{ marginBottom: "20px", paddingLeft: "35px" }}>
                We reserve the right to update or modify this Privacy Policy at
                any time. Any changes will be effective immediately upon posting
                the revised Privacy Policy on our Website. Your continued use of
                our services after the changes will signify your acceptance of
                the updated Privacy Policy.
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                8. Contact Us
              </Typography>

              <Typography sx={{ marginBottom: "40px", paddingLeft: "35px" }}>
                If you have any questions, concerns, or requests regarding this
                Privacy Policy or our privacy practices, please contact us at
                info@ambiancedecorio.com.
              </Typography>

              <Typography sx={{ marginBottom: "40px", paddingLeft: "10px" }}>
                This Privacy Policy was last updated on August 01, 2023.
              </Typography>
            </Grid>
          </Grid>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Policies;
