import React from "react";

import Slide01 from "../../Assets/dummy.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/studyarea/Sa01.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Careers",
  desc: "Lets collabrate to create the future of home interiors",
  bannerimage: Slide01,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export { bannerDetails, consultationFormSectionDetails };
