import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, IconButton, Box, ListItem, List } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { makeStyles } from "@mui/styles";
import theme from "../../Theme";
import { Close as CloseIcon } from "@mui/icons-material";

const MenuItems = [
  { id: 1, name: "Residence", link: "/design-ideas" },
  { id: 2, name: "Commercial", link: "/commercial" },
  { id: 3, name: "Cities", link: "/city/kakinada" },
  { id: 4, name: "Careers", link: "/careers" },
  { id: 5, name: "Contact Us", link: "/contact-us" },
];

const useStyles = makeStyles({
  list: {
    marginBottom: "10px",
    //justifyContent: "center",
  },
  menuitem: {
    textDecoration: "none",
    fontSize: "50px",
    fontWeight: "700",
    textAlign: "center",
    color: `rgba(${theme.palette.primary.default},1)`,

    "&:hover": {
      color: `rgba(${theme.palette.primary.default},.5)`,
    },
  },
});

const HeaderMenu = ({}) => {
  //Custom Style
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div>
        <button onClick={toggleDrawer}>Toggle Drawer</button>

        {/* Use AnimatePresence from Framer Motion to handle enter and exit animations */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} // Adjust the overlay transition duration
            >
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                anchor="right"
                variant="temporary"
                PaperProps={{
                  component: motion.div,
                  initial: { width: 0 },
                  animate: { width: "100%" },
                  exit: { width: 0 },
                  transition: {
                    duration: 0.4, // Adjust the drawer transition duration
                    ease: "easeInOut", // You can also try other easing functions for smoother transitions
                  },
                }}
              >
                <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
                  <IconButton onClick={toggleDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <List>
                      {MenuItems.map(({ name, link, id }) => (
                        <ListItem
                          className={classes.list}
                          component={motion.li}
                          key={id}
                          initial={{ opacity: 0, x: -50 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -50 }}
                          transition={{ duration: 0.4, delay: id * 0.15 }}
                        >
                          <Link key={id} to={link} className={classes.menuitem}>
                            {name}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Drawer>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
};

export default HeaderMenu;
