import React from "react";
import DefaultLayout from "./Layout/DefaultLayout";
import { Avatar, Grid, Typography, Box } from "@mui/material";

import Dummy from "../../Assets/dummy.jpg";

const Profile = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h4" sx={{ marginBottom: "80px" }}>
          Profile{" "}
        </Typography>
        <Grid container>
          <Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginRight: "70px",
                marginBottom: "30px",
              }}
            >
              <Avatar
                sx={{ width: "100px", height: "100px", aspectRatio: "1/1" }}
              >
                <img
                  src={Dummy}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Avatar>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <Typography variant="h6">Omkar Sahu</Typography>
                <Typography variant="small">+91 9000 00 0000</Typography>
                <Typography variant="small">omkar@tektreeinc.com</Typography>
              </Box>
            </Box>
            <Typography variant="h6">Address</Typography>
            <Typography>
              Plot No. 6, Vijay Soudha, NCL Enclave, Beside Balaji Hospital, Pet
              Basheerabad, Near Suchitra Junction, Ranga Reddy District
            </Typography>
          </Grid>
          <Grid flex={1}></Grid>
        </Grid>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Profile;
