import React from "react";

import Slide01 from "../../Assets/dummy3.jpg";
import BannerImage from "../../Assets/images/designideas/Di05.jpg";
import ModularKitchenDesigns from "../../Assets/images/kitchen/parallel/Pa14.jpg";
import WardrobeDesigns from "../../Assets/images/wardrobe/Wd02.jpg";
import MasterBedroomDesigns from "../../Assets/images/master-bed-room/Mb05.jpg";
import LivingRoomDesigns from "../../Assets/images/living-area/La02.jpg";
import PoojaRoomDesigns from "../../Assets/images/pooja-room/Pr03.jpg";
import TVUnitDesigns from "../../Assets/images/tv-units/Tvu04.jpg";
import KidsBedroomDesigns from "../../Assets/images/kids-bedroom/Kbr05.jpg";
import DiningRoomDesigns from "../../Assets/images/dining-room/Dr02.jpg";
import GuestBedroomDesigns from "../../Assets/images/master-bed-room/Mb13.jpg";
import WorkstationsDesigns from "../../Assets/images/workstations/Ws11.jpg";
import OfficeTables from "../../Assets/images/office-table/Ot06.jpg";
import StorageUnits from "../../Assets/images/storage-units/Su01.jpg";
//BgConsultationFormSection
import BgConsultationFormSection from "../../Assets/images/designideas/Di01.jpg";

const BannerDetails = {
  title: "Elevate your living space with exceptional interior design.",
  desc: "Discover a world of meticulously curated interior design ideas that will breathe new life into your home. Explore our exclusive collection of inspiring and practical designs, thoughtfully crafted to transform your kitchen, bedroom, living room, and more. Our team of talented interior designers has meticulously compiled a range of options to cater to your unique home interior requirements.",
  image: BannerImage,
};

const DesignIdeasGallery = [
  {
    id: 1,
    title: "Modular Kitchen Designs",
    imageUrl: ModularKitchenDesigns,
    category: "Modular Kitchen Designs",
    link: "/design-ideas/modular-kitchen-designs",
  },
  {
    id: 2,
    title: "Wardrobe Designs",
    imageUrl: WardrobeDesigns,
    category: "Wardrobe Designs",
    link: "/design-ideas/wardrobe-designs",
  },
  {
    id: 3,
    title: "Master Bedroom Designs",
    imageUrl: MasterBedroomDesigns,
    category: "Master Bedroom Designs",
    link: "/design-ideas/master-bedroom-designs",
  },
  {
    id: 4,
    title: "Living Room Designs",
    imageUrl: LivingRoomDesigns,
    category: "Living Room Designs",
    link: "/design-ideas/living-room-designs",
  },
  {
    id: 5,
    title: "Pooja Room Designs",
    imageUrl: PoojaRoomDesigns,
    category: "Pooja Room Designs",
    link: "/design-ideas/pooja-room-designs",
  },
  {
    id: 6,
    title: "TV Unit Designs",
    imageUrl: TVUnitDesigns,
    category: "TV Unit Designs",
    link: "/design-ideas/tv-unit-designs",
  },
  {
    id: 7,
    title: "Kids Bedroom Designs",
    imageUrl: KidsBedroomDesigns,
    category: "Kids Bedroom Designs",
    link: "/design-ideas/kids-bedroom-designs",
  },
  {
    id: 8,
    title: "Dining Room Designs",
    imageUrl: DiningRoomDesigns,
    category: "Dining Room Designs",
    link: "/design-ideas/dining-room-designs",
  },
  {
    id: 9,
    title: "Guest Bedroom Designs",
    imageUrl: GuestBedroomDesigns,
    category: "Guest Bedroom Designs",
    link: "/design-ideas/guest-bedroom-designs",
  },
  {
    id: 10,
    title: "Workstations Designs",
    imageUrl: WorkstationsDesigns,
    category: "Workstations",
    link: "/design-ideas/workstations",
  },
  {
    id: 11,
    title: "Office Tables",
    imageUrl: OfficeTables,
    category: "Office Tables",
    link: "/design-ideas/office-tables",
  },
  {
    id: 12,
    title: "Storage Units",
    imageUrl: StorageUnits,
    category: "Storage Units",
    link: "/design-ideas/storage-units",
  },
];

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export { BannerDetails, DesignIdeasGallery, consultationFormSectionDetails };
