import React from 'react';
import DefaultLayout from './Layout/DefaultLayout';
import { Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h4">Dashboard</Typography>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Dashboard;
