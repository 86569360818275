import React from "react";
import { useLocation } from "react-router-dom";
//Custom Components
import Header from "../Essentials/Header";
import Footer from "../Essentials/Footer";
import HeaderMenu from "../Essentials/HeaderMenu";
import ScrollToTopButton from "../HelperComponents/ScrollToTopButton";
import FixedMenu from "../HelperComponents/FixedMenu";

const DefaultTemplate = (props) => {
  const { children } = props;
  const location = useLocation();

  // Check if the current location is the home page ("/")
  const isHomePage = location.pathname === "/";

  return (
    <React.Fragment>
      {/* {!isHomePage && <Header />} */}
      <Header />

      {children}
      <Footer />
      {/* <FixedMenu /> */}
      <ScrollToTopButton />
    </React.Fragment>
  );
};

export default DefaultTemplate;
