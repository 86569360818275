import React from "react";
//MUI
import theme from "../../../../Theme";
import { Box, Button, Typography } from "@mui/material";

const LayoutSingleSlider = ({ title, desc, imageUrl, handleClickOpen }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: {
            xs: "60px",
            md: "100px",
          },
          alignItems: "center",
          height: "100%",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: "10px",
              textAlign: {
                xs: "center",
                md: "inherit",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: {
                xs: "center",
                md: "inherit",
              },
            }}
          >
            {desc}
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              color: `rgba(${theme.palette.text.white},1)`,
              padding: "15px 40px",
              boxSizing: "border-box",
              cursor: "inherit",
              marginTop: "30px",
              display: "block",
              margin: {
                xs: "30px auto",
                md: "30px 0",
              },
            }}
            onClick={handleClickOpen}
          >
            Get Expert Advice
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            position: "relative",
            //backgroundImage: `url(${item.imageUrl})`,
            //filter: "blur(8px)",
            "&::before": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: "-1",
              backgroundImage: `linear-gradient(0deg, rgba(255,255,255, .85), rgba(255,255,255, 0.85)),url(${imageUrl})`,
              //backgroundImage: `url(${imageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",

              filter: "blur(2px)",
            },
          }}
        >
          <img
            src={imageUrl}
            loading="lazy"
            alt={title}
            style={{
              maxHeight: "500px",
              width: "auto",
              // height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LayoutSingleSlider;
