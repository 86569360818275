import React from "react";
import theme from "../../../Theme";
import { makeStyles } from "@mui/styles";

const FromFieldStyles = makeStyles((theme) => ({
  customTextField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: `rgba(${theme.palette.text.white},.5)`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `rgba(${theme.palette.text.white},1)`,
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `rgba(${theme.palette.text.white},.8)`,
    },
    "& .MuiOutlinedInput-input": {
      color: `rgba(${theme.palette.text.white},.8)`,
    },
    "& .MuiInputLabel-root": {
      color: `rgba(${theme.palette.text.white},.5)`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "white",
    },

    //Switch
    "& .MuiSwitch-root": {
      width: "68px",
      height: "36px",
      padding: "2px",
    },
    "& .MuiSwitch-switchBase": {
      padding: "4px",
      "&.Mui-checked": {
        transform: "translateX(30px)",
        "& + .MuiSwitch-track": {
          backgroundColor: `rgba(${theme.palette.text.white},.01)`,
          border: `1px solid rgba(${theme.palette.text.white},.1)`,
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: "30px",
      height: "30px",
      boxShadow: "none",
      backgroundColor: `rgba(${theme.palette.text.white},.2)`,
    },
    "& .MuiSwitch-track": {
      borderRadius: "25px",
      backgroundColor: `rgba(${theme.palette.text.white},.01)`,
      border: `1px solid rgba(${theme.palette.text.white},.1)`,
      opacity: 1,
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        backgroundColor: `rgba(${theme.palette.primary.default},1)`,
      },
    },
  },
  "& .Mui-checked ~ .labelClass": {
    color: `rgba(${theme.palette.text.white},.5)`,
  },

  labelClass: {
    color: `rgba(${theme.palette.text.white},.5)`,
    textIndent: "10px",
  },
}));

export { FromFieldStyles };
