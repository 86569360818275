import React, { useState, useRef } from "react";

//OwlCarousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//MUI
import theme from "../../Theme";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Modal,
  SvgIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import Section from "../../Component/HelperComponents/Section";
import LineHeader from "../../Component/HelperComponents/LineHeader";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import ExpertHands from "../../Component/HelperComponents/ExpertHands/ExpertHands";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
//Images and Icons
import BgReasonsEntrust from "../../Assets/images/kitchen/K2.jpg";
import SideLamp from "../../Assets/images/bg-image/slide-lamps.png";
import BgTestimonials from "../../Assets/images/bg-image/sofa.png";
import { arrow } from "../../Assets/icons/icons";

//data
import {
  bannerDetails,
  OfficesDetails,
  RetailsDetails,
} from "../../Data/Commercial/Commercial";

import { testimonialsSettings, Testimonials } from "../../Data/Testimonials";

const useStyles = makeStyles({
  iconlistset: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  },
  iconlist: {},
});

const Commercial = () => {
  //Custom Style
  const classes = useStyles();

  //Modal Setup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  //Modal Setup End

  // Reference to the OwlCarousel instance
  const owlCarouselRef = useRef(null);

  const handlePrevClick = () => {
    if (owlCarouselRef.current) {
      // Go to the previous slide
      owlCarouselRef.current.prev();
    }
  };

  const handleNextClick = () => {
    if (owlCarouselRef.current) {
      // Go to the next slide
      owlCarouselRef.current.next();
    }
  };

  return (
    <React.Fragment>
      <DefaultLayout>
        <Box>
          <OwlCarousel
            className="owl-theme"
            items={1}
            dots={false}
            loop
            ref={owlCarouselRef}
          >
            {bannerDetails.map((item, key) => (
              <Container
                maxWidth={false}
                sx={{
                  paddingTop: "80px",
                  background: `linear-gradient(90deg, rgba(${item.bgcol}) 80%, rgba(255,255,255,1) 80%)`,
                }}
              >
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    gap: "00px",
                    alignItems: {
                      xs: "flex-start",
                      md: "flex-end",
                    },
                    alignContent: "center",
                    flexDirection: {
                      xs: "column-reverse",
                      md: "row",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        md: "350px",
                      },
                      padding: {
                        xs: "30px 0",
                        md: "",
                      },
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: {
                          xs: "",
                          md: "absolute",
                        },
                        bottom: "120px",
                        zIndex: "2",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "10px",
                          position: "relative",
                          width: "400px",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: "10px",
                          position: "relative",
                          width: "400px",
                        }}
                      >
                        {item.desc}
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          color: `rgba(${theme.palette.text.white},1)`,
                          padding: "15px 40px",
                          boxSizing: "border-box",
                          cursor: "inherit",
                          marginTop: "30px",
                          display: "block",
                        }}
                      >
                        Get Expert Advice
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      maxHeight: "calc(100vh - 70px)",
                      position: "relative",
                    }}
                  >
                    <img
                      alt={item.title}
                      src={item.imageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Box>
              </Container>
            ))}
          </OwlCarousel>

          <Box sx={{ position: "absolute", right: 0 }}>
            <Button
              sx={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
              onClick={handlePrevClick}
            >
              <SvgIcon component={arrow} sx={{ rotate: "180deg" }} />
            </Button>

            <Button
              color="text"
              sx={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
              onClick={handleNextClick}
            >
              <SvgIcon component={arrow} />
            </Button>
          </Box>
        </Box>

        {/* Offices and Retails */}
        <Section
          backimg={SideLamp}
          backimgposition="95% 0"
          bgcolor="255,255,255"
        >
          <Typography variant="h3">
            Transforming commercial spaces with innovative interior solutions
            for diverse sectors.
          </Typography>

          <Typography>
            Experience a fresh perspective on commercial interiors as we
            prioritize creating spaces that are aesthetically pleasing,
            functional, and future-proof.
          </Typography>
          <SectionTitle
            title="Offices"
            shadowtext="Offices"
            variant="h5"
            margin="90px 0 0 0"
          />

          <CustomCarouselMain data={OfficesDetails} />
          <SectionTitle title="Retails" shadowtext="Retails" variant="h5" />
          <CustomCarouselMain data={RetailsDetails} />
        </Section>
        {/* Offices and Retails End */}

        <Section>
          <Container maxWidth={"xl"}>
            <Grid container spacing={{ xs: 5, md: 14 }}>
              <Grid item xs={12} md={6}>
                <SectionTitle
                  title="Services we offer"
                  shadowtext="Services we offer"
                />
                <Typography>
                  We provide an array of services to all your commercial
                  interior needs
                </Typography>
                <Box className={classes.iconlistset}>
                  <Box className={classes.iconlist}>
                    <Typography variant="h6">Planning:</Typography>
                    <Typography>
                      We have been there with you from the very beginning until
                      we deliver the project to you, ensuring that we address
                      all your requirements
                    </Typography>
                  </Box>
                  <Box className={classes.iconlist}>
                    <Typography variant="h6">Design:</Typography>
                    <Typography>
                      We have been there with you from the very beginning until
                      we deliver the project to you, ensuring that we address
                      all your requirements
                    </Typography>
                  </Box>
                  <Box className={classes.iconlist}>
                    <Typography variant="h6">Installation:</Typography>
                    <Typography>
                      We have been there with you from the very beginning until
                      we deliver the project to you, ensuring that we address
                      all your requirements
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  alt="Services we offer"
                  src={BgReasonsEntrust}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Section>
        {/* Section Reasons Entrust */}
        <Section
          bgimg={BgReasonsEntrust}
          bgcolor="transparent"
          padding="0"
          bgposition="center center"
        >
          <Grid container>
            <Grid item xs={12} md={5} lg={7}></Grid>
            <Grid item xs={12} md={7} lg={5} sx={{ position: "relative" }}>
              <Box
                sx={{
                  width: "calc(100% - 30px)",
                  maxWidth: "450px",
                  margin: "0px auto",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: `rgba(${theme.palette.text.default},.9)`,
                    bottom: "-50px",
                    width: "100%",
                    minHeight: "200px",
                    padding: "60px 35px",
                    boxSizing: "border-box",
                    color: `rgba(${theme.palette.text.white},1)`,
                    borderRadius: "10px",
                  }}
                >
                  <LineHeader
                    title="Your"
                    title2="Interior Dreams in Our Expert Hands"
                    type="light"
                  />
                  <ExpertHands />

                  <Box sx={{ textAlign: "center", margin: "30px 0 0 0" }}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        color: `rgba(${theme.palette.text.white},1)`,
                        padding: "15px 40px",
                        boxSizing: "border-box",
                      }}
                      onClick={handleClickOpen}
                    >
                      Get free consultation
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>
        {/* Section Testimonials */}
        <Section backimg={BgTestimonials} backimgposition="0 bottom">
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}>
                <LineHeader
                  title="Hear the Stories and Testimonials"
                  title2="That Showcase Our Commitment to Excellence."
                  col="primary"
                />
                <SectionTitle title="Let Our Clients Speak" />
              </Grid>
              <Grid></Grid>
            </Grid>
            <Grid container>
              <Grid>
                <CustomCarouselMain
                  data={Testimonials}
                  type={"testimonials"}
                  settings={testimonialsSettings}
                />
              </Grid>
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            border: "none",
            backgroundColor: `rgba(${theme.palette.text.default},.9)`,
            boxShadow: 24,
            p: 4,
            "&:focus": {
              outline: "none", // Remove focus outline from the modal
            },
          }}
        >
          <ConsultationForm />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Commercial;
