import React, { useState } from "react";
import { useParams } from "react-router-dom";
//MUI
import theme from "../../Theme";
import {
  Box,
  Container,
  Grid,
  List,
  Typography,
  Button,
  Modal,
} from "@mui/material";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import ExpertHands from "../../Component/HelperComponents/ExpertHands/ExpertHands";
import Faqs from "../../Component/HelperComponents/Faqs/Faqs";
import LineHeader from "../../Component/HelperComponents/LineHeader";

import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import PageBreadcrumbs from "../../Component/HelperComponents/Breadcrumbs";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ModalSection from "../../Component/HelperComponents/ModalSection";
//Images and Icons
import BgBanner from "../../Assets/kakinada.jpg";
import BgReasonsEntrust from "../../Assets/images/bg-image/kitchen.jpg";
import BgTestimonials from "../../Assets/images/bg-image/sofa.png";
import { Creativity } from "../../Assets/icons/icons";

//Data
import {
  carouselMainSettings as carouselMainSettingsKakinada,
  whatWeOffer as whatWeOfferKakinada,
} from "../../Data/Cities/Kakinada";

import {
  carouselMainSettings as carouselMainSettingsGuntur,
  whatWeOffer as whatWeOfferGuntur,
} from "../../Data/Cities/Guntur";

import {
  carouselMainSettings as carouselMainSettingsHyderabad,
  whatWeOffer as whatWeOfferHyderabad,
} from "../../Data/Cities/Hyderabad";

import {
  carouselMainSettings as carouselMainSettingsVijayawada,
  whatWeOffer as whatWeOfferVijayawada,
} from "../../Data/Cities/Vijayawada";

import { testimonialsSettings, Testimonials } from "../../Data/Testimonials";
import { maxWidth } from "@mui/system";

const CityPages = () => {
  const { innerPages } = useParams();

  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  //Data
  // Determine the data based on the inner page
  let carouselMainSettings;
  let whatWeOffer;

  if (innerPages === "kakinada") {
    carouselMainSettings = carouselMainSettingsKakinada;
    whatWeOffer = whatWeOfferKakinada;
  } else if (innerPages === "vijayawada") {
    carouselMainSettings = carouselMainSettingsVijayawada;
    whatWeOffer = whatWeOfferVijayawada;
  } else if (innerPages === "hyderabad") {
    carouselMainSettings = carouselMainSettingsHyderabad;
    whatWeOffer = whatWeOfferHyderabad;
  } else if (innerPages === "guntur") {
    carouselMainSettings = carouselMainSettingsGuntur;
    whatWeOffer = whatWeOfferGuntur;
  }

  const cityName = innerPages.toUpperCase();

  return (
    <DefaultLayout>
      {/* Section Reasons Entrust */}
      <Section
        bgimg={BgBanner}
        bgcolor="0,0,0,.5"
        padding="0"
        bgposition="50% 50%"
      >
        <Grid container>
          <Grid item xs={12} md={7} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: { xs: "inherit", md: "absolute" },
                bottom: { xs: "0", md: "50px" },
                left: { xs: "0", md: "10%" },
                marginTop: "140px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "30px", md: "50px" },
                  fontWeight: "700",
                  color: "white",
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
              >
                Interior design in
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "15vmin",
                    md: "100px",
                  },
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                  fontWeight: "700",
                  color: "transparent",
                  WebkitTextStroke: "2px white",
                  //-webkit-text-stroke: 1px black;
                }}
              >
                {cityName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} sx={{ position: "relative" }}>
            <Box
              sx={{
                width: "calc(100% - 30px)",
                maxWidth: "450px",
                margin: "0px auto",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: `rgba(${theme.palette.text.default},.9)`,
                  bottom: "-50px",
                  width: "100%",
                  minHeight: "200px",
                  padding: "60px 35px",
                  boxSizing: "border-box",
                  color: `rgba(${theme.palette.text.white},1)`,
                  borderRadius: "10px",
                }}
              >
                <ConsultationForm />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
      {/* Banner End */}

      <Section>
        <Container maxWidth="xl">
          <Grid container>
            <Grid xs={12} md={6}>
              <PageBreadcrumbs marginbottom={"20"} />
              <Typography variant="h3">
                Have you recently purchased a new home?
              </Typography>
              <Typography>
                "Create interiors that will delight your family members and
                friends with the assistance of our talented interior designers
                in Hyderabad"
              </Typography>
            </Grid>
            <Grid xs={6}></Grid>
          </Grid>

          {whatWeOffer ? (
            <CustomCarouselMain
              data={whatWeOffer}
              type="imagetitleover"
              asratio="1/.75"
              settings={carouselMainSettings}
            />
          ) : null}
        </Container>
        <Container maxWidth="xl">
          <Typography variant="h3">Reasons to Choose Us</Typography>
          <Grid container alignItems="center" spacing={5}>
            <Grid item xs={12} md={8}>
              <Typography>
                Our interior designers are skilled in creating homes that
                accommodate a wide range of budgets. We work closely with you to
                understand your specific requirements and financial limitations,
                ensuring that we design a home that not only meets your needs
                but also fits comfortably within your budget.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ textAlign: { xs: "center", md: "" } }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  color: `rgba(${theme.palette.text.white},1)`,
                  padding: "15px 40px",
                  boxSizing: "border-box",
                }}
                onClick={handleClickOpen}
              >
                Get free Quote
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>

      {/* Section Reasons Entrust */}
      <Section bgimg={BgReasonsEntrust} bgcolor="transparent" padding="0">
        <Grid container>
          <Grid item xs={12} md={5} lg={7}></Grid>
          <Grid item xs={12} md={7} lg={5} sx={{ position: "relative" }}>
            <Box
              sx={{
                width: "calc(100% - 30px)",
                maxWidth: "450px",
                margin: "0px auto",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: `rgba(${theme.palette.text.default},.9)`,
                  bottom: "-50px",
                  width: "100%",
                  minHeight: "200px",
                  padding: "60px 35px",
                  boxSizing: "border-box",
                  color: `rgba(${theme.palette.text.white},1)`,
                  borderRadius: "10px",
                }}
              >
                <LineHeader
                  title="Your"
                  title2="Interior Dreams in Our Expert Hands"
                  type="light"
                />
                <ExpertHands />

                <Box sx={{ textAlign: "center", margin: "30px 0 0 0" }}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      color: `rgba(${theme.palette.text.white},1)`,
                      padding: "15px 40px",
                      boxSizing: "border-box",
                    }}
                    onClick={handleClickOpen}
                  >
                    Get free consultation
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
      {/* Section Testimonials */}
      <Section backimg={BgTestimonials} backimgposition="0 bottom">
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LineHeader
                title="Hear the Stories and Testimonials"
                title2="That Showcase Our Commitment to Excellence."
                col="primary"
              />
              <SectionTitle title="Let Our Clients Speak" />
            </Grid>
            <Grid></Grid>
          </Grid>
          <Grid container>
            <Grid>
              <CustomCarouselMain
                data={Testimonials}
                type={"testimonials"}
                settings={testimonialsSettings}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgcolor="white">
        <Container>
          <SectionTitle title="Frequently Asked Questions" shadowtext="FAQ's" />

          <Faqs />
        </Container>
      </Section>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm />
      </ModalSection>
    </DefaultLayout>
  );
};

export default CityPages;
