import React from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import { Grid, Box, Container } from "@mui/material";
import theme from "../../../Theme";

const DefaultLayout = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <Grid container sx={{ height: "100vh" }}>
        {/* Sidebar */}
        <Grid>
          <Sidebar />
        </Grid>
        {/* Main Content */}
        <Grid
          flex={1}
          item
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Header />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",

              backgroundColor: `rgba(${theme.palette.text.default},.05)`,
            }}
          >
            <Container maxWidth={false} sx={{ margin: "30px 0 50px 0" }}>
              {children}
            </Container>
          </Box>

          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DefaultLayout;
