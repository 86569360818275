import React, { useState } from "react";
//MUI
import theme from "../Theme";
import { Box, Container, Grid, Button, Typography } from "@mui/material";

//Custom Componemts
import DefaultLayout from "../Component/Layout/DefaultLayout";
import SectionTitle from "../Component/HelperComponents/SectionTitle";
import HomeBanner from "../Component/HelperComponents/BannerComponent/HomeBanner";
import HomeBannerNew from "../Component/HelperComponents/BannerComponent/HomeBannerNew";
import Section from "../Component/HelperComponents/Section";
import LineHeader from "../Component/HelperComponents/LineHeader";
import SectionScrolling from "../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import ConsultationForm from "../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ExpertHands from "../Component/HelperComponents/ExpertHands/ExpertHands";
import ModalSection from "../Component/HelperComponents/ModalSection";
//Images and Icons
import SideLamp from "../Assets/images/bg-image/slide-lamps.png";
import BgForInspiration from "../Assets/images/bg-image/kitchen-outline.jpg";
import BgReasonsEntrust from "../Assets/images/kitchen/K2.jpg";

//Data
import {
  modularInteriors,
  fullHomeInteriors,
  PrallexDetails,
  forInspiration,
  TestimonialsSection,
  carouselSettingsHomeSlider,
} from "../Data/Home";

//Data
import {
  carouselSettings,
  whatWeOffer,
} from "../Data/ModularInteriors/ModularInteriors";

import { testimonialsSettings, Testimonials } from "../Data/Testimonials";

const Home = () => {
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  const sideLampBackgroundsize = {
    xs: "90px",
    sm: "100px",
    md: "auto",
    lg: "auto",
    xl: "auto",
  };

  return (
    <React.Fragment>
      <DefaultLayout>
        <Container maxWidth={false} disableGutters={true}>
          {/* <HomeBanner handleClickOpen={handleClickOpen} /> */}
          <HomeBannerNew />
          {/* Modular Interiors and Full Home Interiors */}
          <Section backimg={SideLamp} backgroundsize={sideLampBackgroundsize}>
            <Typography variant="h3" textAlign={"center"}>
              Your Ultimate Interiors Destination
            </Typography>

            <SectionTitle
              title="What we Offer"
              shadowtext="What we Offer"
              variant="h5"
              margin="90px 0 0 0"
            />
            {/* <CustomCarouselMain
              data={modularInteriors}
              settings={carouselSettingsHomeSlider}
            /> */}
            <CustomCarouselMain
              type="imagetitlecont"
              data={whatWeOffer}
              showbutton={false}
              settings={carouselSettingsHomeSlider}
            />

            <SectionTitle
              title="Full Home Interiors"
              shadowtext="Full Home Interiors"
              variant="h5"
            />
            <CustomCarouselMain
              data={fullHomeInteriors}
              settings={carouselSettingsHomeSlider}
            />
          </Section>
          {/* Modular Interiors and Full Home Interiors End */}
          {/* Parallax Image Scrolling */}
          <Section bgcolor="white" padding="">
            <SectionScrolling
              title={PrallexDetails.title}
              highlight={PrallexDetails.highlight}
              bgimg={PrallexDetails.image}
            />
          </Section>
          {/* Parallax Image Scrolling End */}
          {/* Inspiration */}
          <Section bgimg={BgForInspiration}>
            <SectionTitle title="For Inspiration" shadowtext="Ideas" />
            <CustomCarouselMain
              data={forInspiration}
              showcategories={true}
              linkcategories={true}
              settings={carouselSettingsHomeSlider}
            />
          </Section>
          {/* Inspiration End */}
          {/* Reasons Entrust */}
          <Section
            bgimg={BgReasonsEntrust}
            bgcolor="transparent"
            padding="0"
            bgposition="center center"
          >
            <Grid container>
              <Grid item xs={12} md={5} lg={7}></Grid>
              <Grid item xs={12} md={7} lg={5} sx={{ position: "relative" }}>
                <Box
                  sx={{
                    width: "calc(100% - 30px)",
                    maxWidth: "450px",
                    margin: "0px auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: `rgba(${theme.palette.text.default},.9)`,
                      bottom: "-50px",
                      width: "100%",
                      minHeight: "200px",
                      padding: "60px 35px",
                      boxSizing: "border-box",
                      color: `rgba(${theme.palette.text.white},1)`,
                      borderRadius: "10px",
                    }}
                  >
                    <LineHeader
                      title="Your"
                      title2="Interior Dreams in Our Expert Hands"
                      type="light"
                    />
                    <ExpertHands />

                    <Box sx={{ textAlign: "center", margin: "30px 0 0 0" }}>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          color: `rgba(${theme.palette.text.white},1)`,
                          padding: "15px 40px",
                          boxSizing: "border-box",
                        }}
                        onClick={handleClickOpen}
                      >
                        Get free consultation
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Section>
          {/* Reasons Entrust End */}
          {/* Testimonials */}
          <Section
            backimg={TestimonialsSection.backimg}
            backimgposition={TestimonialsSection.backimgposition}
            backgroundsize={TestimonialsSection.backgroundsize}
          >
            <Container>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <LineHeader
                    title="Hear the Stories and Testimonials"
                    title2="That Showcase Our Commitment to Excellence."
                    col="primary"
                  />
                  <SectionTitle title="Let Our Clients Speak" />
                </Grid>
                <Grid></Grid>
              </Grid>
              <Grid container>
                <Grid>
                  <CustomCarouselMain
                    data={Testimonials}
                    type={"testimonials"}
                    settings={testimonialsSettings}
                  />
                </Grid>
              </Grid>
            </Container>
          </Section>
          {/* Testimonials End */}
        </Container>
      </DefaultLayout>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm />
      </ModalSection>
    </React.Fragment>
  );
};
export default Home;
