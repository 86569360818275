import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

//MUI
import theme from "../../Theme";
import { Box, IconButton } from "@mui/material";

//Image and Icons
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  // Show or hide the button based on the scroll position
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Animate the button when its visibility changes
    controls.start({ opacity: isVisible ? 1 : 0 });
  }, [isVisible, controls]);

  return (
    <React.Fragment>
      <Box
        sx={{ position: "fixed", bottom: "20px", right: "60px", zIndex: 999 }}
        initial={{ opacity: 0 }}
        animate={controls}
      >
        <motion.div initial={{ opacity: 0 }} animate={controls}>
          <IconButton
            onClick={scrollToTop}
            sx={{
              backgroundColor: `rgba(${theme.palette.primary.default},.5)`,
              color: `rgba(${theme.palette.text.white},.7)`,
              "&:hover": {
                backgroundColor: `rgba(${theme.palette.primary.default},1)`,
                color: `rgba(${theme.palette.text.white},1)`,
              },
            }}
          >
            <KeyboardDoubleArrowUpIcon />
          </IconButton>
        </motion.div>
      </Box>
    </React.Fragment>
  );
};

export default ScrollToTopButton;
