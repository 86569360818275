import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../Theme";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  List,
  useMediaQuery,
} from "@mui/material";
//Custom Components
import ExpertHands from "../../Component/HelperComponents/ExpertHands/ExpertHands";

import CopyRights from "../../Component/HelperComponents/CopyRights";

/*Images and Icons */
import Logo from "../../Assets/logo.png";

const LoginRegister = () => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      {/* <span>{`theme.breakpoints.up('md') matches: ${matches}`}</span> */}
      <Grid container sx={{ minHeight: "100vh", flex: 1 }}>
        {matches && (
          <Grid
            sx={{
              backgroundColor: `rgba(${theme.palette.text.default},1)`,
              flex: 1,
              position: "relative",
            }}
          >
            <Box
              sx={{
                backgroundColor: `rgba(${theme.palette.text.default},1)`,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "max-content",
              }}
            >
              <ExpertHands />
            </Box>
          </Grid>
        )}
        <Grid container sx={{ minHeight: "100vh", flex: 1 }}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "100px",
              margin: "30px",
              //alignItems: "center",
              alignItems: !matches ? "center" : "",
            }}
          >
            <Grid>
              <Box component={Link} to="/">
                <img src={Logo} />
              </Box>
            </Grid>
            <Grid>
              <Box sx={{ maxWidth: "480px" }}>
                <Typography variant="h5">Sign In / Sign Up</Typography>
                <form>
                  <TextField
                    id="outlined-basic"
                    label="Enter Email / Mobile Number"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    margin="normal"
                  />
                  <Link to={"/user/enquiries"}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      sx={{ color: `rgba(${theme.palette.text.white},1)` }}
                    >
                      Continue
                    </Button>
                  </Link>
                </form>
              </Box>
            </Grid>
            {!matches && (
              <Grid>
                <ExpertHands
                  listcol={`rgba(${theme.palette.text.default},1)`}
                  listbordercol={`rgba(${theme.palette.primary.default},1)`}
                  iconcol={`rgba(${theme.palette.text.default},1)`}
                  iconcol2={`rgba(${theme.palette.primary.default},1)`}
                />
              </Grid>
            )}
            <Grid>
              <CopyRights textcol={`rgba(${theme.palette.text.default},1)`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LoginRegister;
