import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import BannerImage from "../../Assets/images/tv-units/Tvu18.jpg";

import TVWallUnit from "../../Assets/images/materials/tv-units/tv_wall_unit.jpg";
import FloatingTvUnit from "../../Assets/images/materials/tv-units/floating_tv_unit.jpg";
import CornerTvUnit from "../../Assets/dummy.jpg";
import TvArmoire from "../../Assets/images/materials/tv-units/tv_armoire.jpg";
import MinimalistTvShelf from "../../Assets/images/materials/tv-units/minimalist_tv_shelf.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/tv-units/Tvu14.jpeg";

// Parallex
import BgScrolling from "../../Assets/images/tv-units/Tvu09.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Discover Stylish and Functional TV Units for Your Home",
  desc: "Functionality is at the forefront of our TV units, with thoughtfully designed features such as spacious shelves, drawers, and cabinets to accommodate your media devices, gaming consoles, DVDs, and more. You can keep your entertainment area neat and organized, while also having easy access to all your media essentials.",
  image: BannerImage,
  bannertext: false,
};
const typesTvUnits = [
  {
    id: 1,
    title: "TV Wall Unit",
    imageUrl: TVWallUnit,
    desc: "A large and comprehensive unit that encompasses the TV, storage shelves, cabinets, and sometimes even a fireplace, creating a focal point in the room.",
  },
  {
    id: 2,
    title: "Floating TV Unit",
    imageUrl: FloatingTvUnit,
    desc: 'Wall-mounted unit that gives the illusion of "floating" off the ground, creating a modern and sleek look. Entertainment Center: A combination of shelving, cabinets, and drawers, designed to house not only the TV but also media equipment, books, and decorative items.',
  },
  {
    id: 3,
    title: "Corner TV Unit",
    imageUrl: CornerTvUnit,
    desc: "Designed to fit snugly into a corner of the room, maximizing space utilization and often including shelves or cabinets.",
  },
  {
    id: 4,
    title: "TV Armoire",
    imageUrl: TvArmoire,
    desc: "A cabinet-style unit with doors that can be closed when the TV is not in use, offering a neat and concealed storage solution.",
  },
  {
    id: 5,
    title: "Minimalist TV Shelf",
    imageUrl: MinimalistTvShelf,
    desc: "A simple shelf that holds the TV and a few media components, ideal for a clean and uncluttered appearance.",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export {
  bannerDetails,
  typesTvUnits,
  PrallexDetails,
  consultationFormSectionDetails,
};
