import React from "react";

//MUI Theme
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material"; // Import MUI useMediaQuery

//modular interiors
import Mi01 from "../Assets/images/modularinteriors/modularinterior.jpg";
import Mi02 from "../Assets/images/modularinteriors/modularinteriors.jpg";
import Mi03 from "../Assets/images/modularinteriors/Mi03.jpg";
import Mi04 from "../Assets/images/modularinteriors/Mi04.jpg";
import Mi05 from "../Assets/images/modularinteriors/Mi05.jpg";

//full home interiors
import Fhi01 from "../Assets/images/fullhomeinteriors/Fhi01.jpg";
import Fhi02 from "../Assets/images/fullhomeinteriors/Fhi02.jpg";
import Fhi03 from "../Assets/images/fullhomeinteriors/Fhi03.jpg";
import Fhi04 from "../Assets/images/fullhomeinteriors/Fhi04.jpg";
import Fhi05 from "../Assets/images/fullhomeinteriors/Fhi05.jpg";

//Design Ideas_ for Inspiration
import Di01 from "../Assets/images/kitchen/parallel/Pa11.jpg";
import Di02 from "../Assets/images/wardrobe/Wd04.jpg";
import Di03 from "../Assets/images/bedroom/Br03.jpg";
import Di04 from "../Assets/images/bedroom/Br04.jpg";
import Di05 from "../Assets/images/fullhomeinteriors/Fhi01.jpg";
import Di06 from "../Assets/images/pooja-room/Pr05.jpg";
import Di07 from "../Assets/images/tv-units/Tvu02.jpg";
import Di08 from "../Assets/images/kitchen/iland/Il08.jpg";

// Parallex
//import BgScrolling from "../Assets/images/kitchen/K5.jpg";
import BgScrolling from "../Assets/images/kitchen/K1.jpg";

//BgTestimonials
import BgTestimonials from "../Assets/images/bg-image/sofa.png";

//carouselMainSettings
const carouselMainSettings = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  // // responsive: {
  //   0: {
  //     items: 1,
  //   },
  //   400: {
  //     items: 2,
  //   },
  //   601: {
  //     items: 3,
  //   },
  //   901: {
  //     items: 5,
  //   },
  //   1200: {
  //     items: 9,
  //   },
  // },
};

const modularInteriors = [
  {
    id: 1,
    title: "Modular Interiors 01",
    imageUrl: Mi01,
    link: "/modular-interiors",
  },
  {
    id: 2,
    title: "Modular Interiors 02",
    imageUrl: Mi02,
    link: "/modular-interiors",
  },
  {
    id: 3,
    title: "Modular Interiors 03",
    imageUrl: Mi03,
    link: "/modular-interiors",
  },
  {
    id: 4,
    title: "Modular Interiors 04",
    imageUrl: Mi04,
    link: "/modular-interiors",
  },
  {
    id: 5,
    title: "Modular Interiors 05",
    imageUrl: Mi05,
    link: "/modular-interiors",
  },
];
const fullHomeInteriors = [
  {
    id: 1,
    title: "Full Home Interiors 01",
    imageUrl: Fhi01,
    link: "/full-home-interiors",
  },
  {
    id: 2,
    title: "Full Home Interiors 02",
    imageUrl: Fhi02,
    link: "/full-home-interiors",
  },
  {
    id: 3,
    title: "Full Home Interiors 03",
    imageUrl: Fhi03,
    link: "/full-home-interiors",
  },
  {
    id: 4,
    title: "Full Home Interiors 04",
    imageUrl: Fhi04,
    link: "/full-home-interiors",
  },
  {
    id: 5,
    title: "Full Home Interiors 05",
    imageUrl: Fhi05,
    link: "/full-home-interiors",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const forInspiration = [
  {
    id: 1,
    title: "For Inspiration 01",
    imageUrl: Di01,
    category: "Modular Kitchen Designs",
    link: "/design-ideas",
    categoryLink: "/design-ideas/modular-kitchen-designs",
  },
  {
    id: 2,
    title: "For Inspiration 02",
    imageUrl: Di02,
    category: "Wardrobe Designs",
    link: "/design-ideas",
    categoryLink: "/design-ideas/wardrobe-designs",
  },
  {
    id: 3,
    title: "For Inspiration 03",
    imageUrl: Di03,
    category: "Master Bedroom Designs",
    link: "/design-ideas",
    categoryLink: "/design-ideas/master-bedroom-designs",
  },
  {
    id: 4,
    title: "For Inspiration 04",
    imageUrl: Di04,
    category: "Kids Bedroom Designs",
    link: "/design-ideas",
  },
  {
    id: 5,
    title: "For Inspiration 05",
    imageUrl: Di05,
    category: "Living Room Designs",
    link: "/design-ideas",
  },
  {
    id: 6,
    title: "For Inspiration 06",
    imageUrl: Di06,
    category: "Pooja Room Designs",
    link: "/design-ideas",
  },
  {
    id: 7,
    title: "For Inspiration 07",
    imageUrl: Di07,
    category: "TV Unit Designs",
    link: "/design-ideas",
  },
  {
    id: 8,
    title: "For Inspiration 08",
    imageUrl: Di08,
    category: "Dining Room Designs",
    link: "/design-ideas",
  },
];

const TestimonialsSection = {
  backimg: BgTestimonials,
  backimgposition: "0 bottom",
  backgroundsize: {
    xs: "300px",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
};

const carouselSettingsHomeSlider = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1.2,
    },
    601: {
      items: 2.2,
    },
    901: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

export {
  modularInteriors,
  fullHomeInteriors,
  PrallexDetails,
  forInspiration,
  TestimonialsSection,
  carouselSettingsHomeSlider,
};
