import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../../Theme";
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Image and Icons
import { kanist, anyonya, uttama } from "../../../Assets/icons/icons";

const useStyles = makeStyles({
  linkWrapper: {
    color: `rgba(${theme.palette.text.default},1)`,
    textDecoration: "none",
    display: "block",
    height: "100%",
    transition: "all ease 1s",
    backgroundColor: "transparent",
    "&>:hover": {
      // transition: "all ease 1s",
      // backgroundColor: `rgba(${theme.palette.text.default},.05)`,
    },
  },
  holder: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    height: "100%",
  },
  iconset: {
    paddingTop: "20px",
  },
  textset: { padding: "0 20px 50px" },
});

const RangeType = ({ title, desc, icon, col, col2, pathto }) => {
  const classes = useStyles(); //Custom Style
  return (
    <React.Fragment>
      {/* <Box component={Link} to={pathto} className={classes.linkWrapper}> */}
      <Box className={classes.linkWrapper}>
        <Box className={classes.holder}>
          <Box className={classes.iconset}>
            <SvgIcon
              component={icon}
              sx={{ fontSize: "120px" }}
              col={col}
              col2={col2}
            />
          </Box>
          <Box className={classes.textset}>
            <Typography variant="h6">{title}</Typography>
            <Typography>{desc}</Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

RangeType.defaultProps = {
  title: "add title",
  desc: "add desc",
  col: "#000000",
  col2: "#FECE78",
  pathto: false,
};

export default RangeType;
