import React from "react";

//MUI
import theme from "../../Theme";
import { Typography, Button, Container, Grid } from "@mui/material";

//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
//data
import { bannerDetails } from "../../Data/Others/Careers";

//Image and Icons
import Dummy from "../../Assets/dummy.jpg";

const Careers = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
          type={bannerDetails.type}
        />
        <Section bgcolor="1,1,0,0.0" margin={"40px 0"} padding={"70px 24px"}>
          <Container maxWidth="xl">
            <Grid container alignItems={"center"} spacing={5}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">
                  Where Design Excellence Embraces Technological Advancements
                </Typography>
                <Typography>
                  Ambience Decorio: Leading the Way in Tech-Driven Home
                  Interiors Transformation
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    color: `rgba(${theme.palette.text.white},1)`,
                    padding: "15px 40px",
                    boxSizing: "border-box",
                    cursor: "inherit",
                  }}
                >
                  Open Positions
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Section>
        <Section>
          <Container maxWidth={"xl"}>
            <Grid container alignItems={"center"} spacing={5}>
              <Grid item xs={12} md={6}>
                <img
                  src={Dummy}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">
                  A Remarkable Workplace: Where Greainess Flourishes
                </Typography>
                <Typography>
                  We firmly believe that a great workplace is not just about the
                  physical infrastructure, but about the people who bring it to
                  life. It is their dedication, talent, and shared values that
                  make our organization thrive. Each smile, each idea, and each
                  contribution is instrumental in creating an environment where
                  everyone can reach their fullest potential.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Careers;
