import React from "react";
import { useParams } from "react-router-dom";

const showDataKitchens = [
  /// page is /modular-interiors/kitchens
  { id: 1, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 2, image: "DummyImage", category: "anyonya", layout: "straight" },
  { id: 3, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 4, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 5, image: "DummyImage", category: "kanist", layout: "land" },
  { id: 6, image: "DummyImage", category: "uttama", layout: "parallel" },
  { id: 7, image: "DummyImage", category: "anyonya", layout: "straight" },
  { id: 8, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 9, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 10, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 11, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 12, image: "DummyImage", category: "anyonya", layout: "parallel" },
  { id: 13, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 14, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 15, image: "DummyImage", category: "anyonya", layout: "u-shape" },
  { id: 16, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 17, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 18, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 19, image: "DummyImage", category: "kanist", layout: "u-shape" },
  { id: 20, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 21, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 22, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 23, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 24, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 25, image: "DummyImage", category: "anyonya", layout: "u-shape" },
  { id: 26, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 27, image: "DummyImage", category: "uttama", layout: "l-shape" },
  { id: 28, image: "DummyImage", category: "anyonya", layout: "land" },
  { id: 29, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 30, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 31, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 32, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 33, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 34, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 35, image: "DummyImage", category: "anyonya", layout: "land" },
];

const showDataWardrobes = [
  /// page is /modular-interiors/wardrobes
  { id: 1, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 2, image: "DummyImage", category: "anyonya", layout: "straight" },
  { id: 3, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 4, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 5, image: "DummyImage", category: "kanist", layout: "land" },
  { id: 6, image: "DummyImage", category: "uttama", layout: "parallel" },
  { id: 7, image: "DummyImage", category: "anyonya", layout: "straight" },
  { id: 8, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 9, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 10, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 11, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 12, image: "DummyImage", category: "anyonya", layout: "parallel" },
  { id: 13, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 14, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 15, image: "DummyImage", category: "anyonya", layout: "u-shape" },
  { id: 16, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 17, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 18, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 19, image: "DummyImage", category: "kanist", layout: "u-shape" },
  { id: 20, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 21, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 22, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 23, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 24, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 25, image: "DummyImage", category: "anyonya", layout: "u-shape" },
  { id: 26, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 27, image: "DummyImage", category: "uttama", layout: "l-shape" },
  { id: 28, image: "DummyImage", category: "anyonya", layout: "land" },
  { id: 29, image: "DummyImage", category: "kanist", layout: "l-shape" },
  { id: 30, image: "DummyImage", category: "kanist", layout: "straight" },
  { id: 31, image: "DummyImage", category: "uttama", layout: "land" },
  { id: 32, image: "DummyImage", category: "anyonya", layout: "l-shape" },
  { id: 33, image: "DummyImage", category: "kanist", layout: "parallel" },
  { id: 34, image: "DummyImage", category: "uttama", layout: "u-shape" },
  { id: 35, image: "DummyImage", category: "anyonya", layout: "land" },
];

const showDataAbc = [
  /// page is /modular-interiors/wardrobes
];

const ModularInteriorsFilter = () => {
  const { category, layout } = useParams();

  return <div>ModularInteriorsFilter</div>;
};

export default ModularInteriorsFilter;
