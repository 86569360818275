import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//Framer Motion
import { motion } from "framer-motion";

//MUI
import theme from "../../../Theme";
import {
  Box,
  Typography,
  Button,
  SvgIcon,
  IconButton,
  List,
  ListItem,
} from "@mui/material";

//Image and Icon
import Logo from "../../../Assets/logo.png";
import Slide01 from "../../../Assets/images/home-banner/slide-01.jpg";
import Slide02 from "../../../Assets/images/home-banner/slide-02.jpeg";
import Slide03 from "../../../Assets/images/home-banner/slide-03.jpg";
import Slide04 from "../../../Assets/images/home-banner/slide-04.jpeg";
import Slide05 from "../../../Assets/images/home-banner/slide-05.jpg";
import Slide06 from "../../../Assets/images/home-banner/slide-06.jpeg";
import { sofa, chair, menu } from "../../../Assets/icons/icons";

import { makeStyles } from "@mui/styles";

//Custom Styles

const useStyles = makeStyles({
  linkWrapper: {
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& li": {
      display: "relative",
      listStyleType: "none",
      "& a": {
        right: 0,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        color: `rgba(${theme.palette.text.white},1)`,
        backgroundColor: `rgba(${theme.palette.primary.default},1)`,
        textDecoration: "none",
        borderRadius: "50px 0 0 50px",
      },
    },
  },
});

const HomeBanner = ({ handleClickOpen }) => {
  const classes = useStyles(); //Custom Style

  const slideData = [
    {
      id: 1,
      image: Slide01,
      slidecontent: "Fall in Love with Your Home, In Side and Out.",
      link: "/",
    },
    {
      id: 2,
      image: Slide02,
      slidecontent:
        "Bringing Beauty to Life: Inspiring Interior Designs Tailored to Your Unique Style",
      link: "/",
    },
    {
      id: 3,
      image: Slide03,
      slidecontent:
        "Creating Timeless Spaces: Where Style Meets Imagination in Interior Design",
      link: "/",
    },
    {
      id: 4,
      image: Slide04,
      slidecontent:
        "Designing Dreams: Crafting Extraordinary Interiors that Reflect Your Vision",
      link: "/",
    },
    {
      id: 5,
      image: Slide05,
      slidecontent:
        "Unlock the Beauty Within: Unleashing the Potential of Your Space through Exceptional Interior Design",
      link: "/",
    },
    {
      id: 6,
      image: Slide06,
      slidecontent:
        "Elevate Your Space: Transforming Homes with Exquisite Interior Designs",
      link: "/",
    },
  ];

  const [showLogo, setShowLogo] = useState(false);
  const [showBannersBox, setShowBannersBox] = useState(false);
  const [showBanners, setShowBanners] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isSlideChanging, setIsSlideChanging] = useState(false);

  useEffect(() => {
    const showLogoTimeout = setTimeout(() => {
      setShowLogo(true);
    }, 200);

    const showBannersBoxTimeout = setTimeout(() => {
      setShowBannersBox(true);
    }, 1000);

    let slideInterval = null;
    const showBannersTimeout = setTimeout(() => {
      setShowBanners(true);

      slideInterval = setInterval(() => {
        if (!isSlideChanging) {
          setIsSlideChanging(true);
          setActiveSlide((prevSlide) => (prevSlide + 1) % slideData.length);
          setTimeout(() => {
            setIsSlideChanging(false);
          }, 2000); // Adjust the throttle duration here
        }
      }, 9000); // Adjust the interval delay here
    }, 8000); // Adjust the delay for showing banners

    return () => {
      clearTimeout(showLogoTimeout);
      clearTimeout(showBannersBoxTimeout);
      clearTimeout(showBannersTimeout);
      clearInterval(slideInterval);
    };
  }, [slideData.length]);

  const handleNextSlide = () => {
    if (!isSlideChanging) {
      setIsSlideChanging(true);
      setActiveSlide((prevSlide) => (prevSlide + 1) % slideData.length);
      setTimeout(() => {
        setIsSlideChanging(false);
      }, 2000); // Adjust the throttle duration here
    }
  };

  const handlePrevSlide = () => {
    if (!isSlideChanging) {
      setIsSlideChanging(true);
      setActiveSlide((prevSlide) =>
        prevSlide === 0 ? slideData.length - 1 : prevSlide - 1
      );
      setTimeout(() => {
        setIsSlideChanging(false);
      }, 2000); // Adjust the throttle duration here
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          boxSizing: "border-box",
          display: "flex",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {showLogo && (
          <Box
            sx={{
              position: "absolute",
              zIndex: "1",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <motion.img
              src={Logo}
              initial={{ scale: 0.5 }}
              animate={{ scale: 1.5 }}
              transition={{ duration: 0.5 }}
            />
          </Box>
        )}

        {showBannersBox && (
          <Box
            sx={{
              position: "absolute",
              zIndex: "2",
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "0px",
            }}
          >
            {slideData.map((item, key) => (
              <Box
                sx={{
                  backgroundImage: `url(${item.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                component={motion.div}
                key={item.id}
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 3, delay: key * 0.7 }}
              ></Box>
            ))}
          </Box>
        )}

        {showBanners && (
          <>
            <Box
              sx={{
                position: "absolute",
                zIndex: "3",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                backgroundColor: "white",
                gap: "0px",
                overflow: "clip",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={motion.div}
                key={slideData[activeSlide].id}
                initial={{ opacity: 0.7 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                sx={{
                  backgroundImage: `url(${slideData[activeSlide].image})`,
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                }}
              >
                <Box
                  component={motion.div}
                  initial={{ opacity: 0, bottom: "-100%" }}
                  animate={{ opacity: 1, bottom: 0 }}
                  transition={{ duration: 1 }}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: {
                      xs: "rgba(255, 255, 255, .9)",
                      sm: "rgba(255, 255, 255, .8)",
                    },
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    padding: {
                      xs: "25px",
                      sm: "45px",
                    },
                    width: {
                      xs: "calc(100% - 0px)",
                      sm: "calc(100% - 250px)",
                    },
                    boxSizing: "border-box",
                    justifyContent: "space-between",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      display: {
                        xs: "block",
                        sm: "none",
                      },
                      top: "-60px",
                      right: "25px",
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: `rgba(${theme.palette.primary.default},.0)`,
                        color: `rgba(${theme.palette.primary.default},1)`,
                        "&:hover": {
                          backgroundColor: `rgba(${theme.palette.primary.default},1)`,
                          color: `rgba(${theme.palette.text.white},1)`,
                        },
                      }}
                      onClick={handlePrevSlide}
                    >
                      <SvgIcon component={menu} sx={{ padding: "6px" }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        backgroundColor: `rgba(${theme.palette.primary.default},.0)`,
                        color: `rgba(${theme.palette.primary.default},1)`,
                        "&:hover": {
                          backgroundColor: `rgba(${theme.palette.primary.default},1)`,
                          color: `rgba(${theme.palette.text.white},1)`,
                        },
                      }}
                      onClick={handleNextSlide}
                    >
                      <SvgIcon
                        component={menu}
                        sx={{ padding: "6px", transform: "rotateY(180deg)" }}
                      />
                    </IconButton>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textWrap: "balance",
                      textAlign: {
                        xs: "center",
                        sm: "inherit",
                      },
                    }}
                  >
                    {slideData[activeSlide].slidecontent}
                  </Typography>
                  <Box>
                    <Button
                      onClick={handleClickOpen}
                      variant="contained"
                      size="large"
                      sx={{
                        color: "rgba(255, 255, 255, 1)",
                        padding: "15px 40px",
                        boxSizing: "border-box",
                        whiteSpace: "nowrap",
                        cursor: "inherit",
                      }}
                    >
                      Get free consultation
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "6",
                  bottom: 30,
                  right: 50,
                  display: "flex",
                  gap: "20px",

                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: `rgba(${theme.palette.primary.default},.0)`,
                    color: `rgba(${theme.palette.primary.default},1)`,
                    "&:hover": {
                      backgroundColor: `rgba(${theme.palette.primary.default},1)`,
                      color: `rgba(${theme.palette.text.white},1)`,
                    },
                  }}
                  onClick={handlePrevSlide}
                >
                  <SvgIcon component={menu} sx={{ padding: "6px" }} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: `rgba(${theme.palette.primary.default},.0)`,
                    color: `rgba(${theme.palette.primary.default},1)`,
                    "&:hover": {
                      backgroundColor: `rgba(${theme.palette.primary.default},1)`,
                      color: `rgba(${theme.palette.text.white},1)`,
                    },
                  }}
                  onClick={handleNextSlide}
                >
                  <SvgIcon
                    component={menu}
                    sx={{ padding: "6px", transform: "rotateY(180deg)" }}
                  />
                </IconButton>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default HomeBanner;
