import React from "react";
import Slide01 from "../../Assets/dummy.jpg";

//carouselMainSettings
const carouselMainSettings = {
  //margin: 60,
  loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  autoplay: false,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1.2,
    },
    900: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const whatWeOffer = [
  {
    id: 1,
    title: "We Design",
    imageUrl: Slide01,
  },
  {
    id: 2,
    title: "We Curate",
    imageUrl: Slide01,
  },
  {
    id: 3,
    title: "We Deliver",
    imageUrl: Slide01,
  },
];

const faqs = [
  {
    label: "City",
    content: "Content for Tab 1",
    accordions: [
      { title: "Accordion 1", content: "Accordion Content 1" },
      { title: "Accordion 2", content: "Accordion Content 2" },
      { title: "Accordion 3", content: "Accordion Content 3" },
    ],
  },
  {
    label: "City",
    content: "Content for Tab 2",
    accordions: [
      { title: "Accordion 1", content: "Accordion Content 1" },
      { title: "Accordion 2", content: "Accordion Content 2" },
      { title: "Accordion 3", content: "Accordion Content 3" },
      { title: "Accordion 4", content: "Accordion Content 4" },
      { title: "Accordion 5", content: "Accordion Content 4" },
      { title: "Accordion 6", content: "Accordion Content 4" },
      { title: "Accordion 7", content: "Accordion Content 4" },
      { title: "Accordion 8", content: "Accordion Content 4" },
    ],
  },
];

export { carouselMainSettings, faqs, whatWeOffer };
