import React from "react";
//MUI
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";
import RangeType from "./RangeType";

//Image and Icons
import { kanist, uttama, athyuttama, dot } from "../../../Assets/icons/icons";

const RangeSet = ({ link, title, desc }) => {
  const kanistLink = `${link}/kanist`;
  const anyonyaLink = `${link}/anyonya`;
  const uttamaLink = `${link}/uttama`;
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Container sx={{ marginBottom: "20px" }}>
          <Typography
            variant="h5"
            textAlign={"center"}
            sx={{ textWrap: "balance", marginBottom: "20px" }}
          >
            {title}
          </Typography>
          <Typography textAlign={"center"}>{desc}</Typography>
        </Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
            <RangeType
              icon={kanist}
              title="Kanist"
              desc="Discover our budget-friendlv collection that offers everything you need for a fresh start,"
              pathto={kanistLink}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
            <RangeType
              icon={uttama}
              title="Uttam"
              desc="Experience the epitome of elegance and sophistication with our
        exquisite finishes on modular products, designed to make your
        home truly stand out in style."
              col2="#FEAB66"
              pathto={anyonyaLink}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
            <RangeType
              icon={uttama}
              title="Athyuttama"
              desc="Crafted with reinforced aluminum, our range offers effortless
        installation under fixed countertops, showcasing unparalleled
        flexibility at its finest."
              col2="#FF7C7C"
              pathto={uttamaLink}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

RangeSet.defaultProps = {
  title: "add title",
  desc: "add desc",
};

export default RangeSet;
