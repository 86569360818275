import React from "react";
//MUI
import { ListItem, SvgIcon } from "@mui/material";

const ItemList = ({ title, icon, col, col2, textcol }) => {
  return (
    <React.Fragment>
      <ListItem
        sx={{
          fontSize: "20px",
          letterSpacing: ".06em",
          fontWeight: 300,
          color: textcol,
        }}
      >
        <SvgIcon
          component={icon}
          sx={{ fontSize: "60px", marginRight: "10px" }}
          col={col}
          col2={col2}
        />
        {title}
      </ListItem>
    </React.Fragment>
  );
};

ItemList.defaultProps = {
  col: "#F4EDE6",
  col2: "#FECE78",
  textcol: "default",
};

export default ItemList;
