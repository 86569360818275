import React from "react";

import Slide01 from "../../Assets/dummy.jpg";



//carouselSettings
const carouselSettings = {
    partialVisible: true,
    responsive: {
        superLargeDesktop: {
            // xl
            breakpoint: { max: 4000, min: 1536 },
            items: 4,
            partialVisibilityGutter: 40,
        },
        desktop1: {
            //lg
            breakpoint: { max: 1535, min: 1200 },
            items: 3,
            partialVisibilityGutter: 40,
        },
        desktop: {
            //md
            breakpoint: { max: 1199, min: 900 },
            items: 3,
            partialVisibilityGutter: 40,
        },
        tablet: {
            //sm
            breakpoint: { max: 899, min: 600 },
            items: 2,
            partialVisibilityGutter: 40,
        },

        mobile: {
            //xs
            breakpoint: { max: 599, min: 0 },
            items: 1,
            partialVisibilityGutter: 40,
        },
    },
};


const bannerDetails = {
    type: "fullimage",
    title: "This is banner Title",
    desc: "This is banner Description",
    bannerimage: Slide01
}





const Reasons = [
    {
        id: 1,
        title: "Reasons Ambience 01",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 2,
        title: "Reasons Ambience 03",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 3,
        title: "Reasons Ambience 03",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 4,
        title: "Reasons Ambience 04",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 5,
        title: "Reasons Ambience 05",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 6,
        title: "Reasons Ambience 06",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 7,
        title: "Reasons Ambience 07",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 8,
        title: "Reasons Ambience 08",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
];

const Layout = [
    {
        id: 1,
        title: "Layout Type 01",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 2,
        title: "Layout Type 02",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 3,
        title: "Layout Type 03",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 4,
        title: "Layout Type 04",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
    {
        id: 5,
        title: "Layout Type 05",
        imageUrl: Slide01,
        //link: "/home-interiors",
    },
];


export {
    carouselSettings,
    bannerDetails,
    Layout,
    Reasons,
};
