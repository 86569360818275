import { Box, Typography, IconButton, SvgIcon, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { user, menu, wardrobe } from "../../../Assets/icons/icons";

import poojagharMorning from "../../../Assets/slide/poojaghar_morning.jpg";
import poojagharNature from "../../../Assets/slide/poojaghar_nature.jpg";
import poojagharSky from "../../../Assets/slide/poojaghar_sky.jpg";

import tvUnitMorning from "../../../Assets/slide/tv_unit_morning.jpg";
import tvUnitNature from "../../../Assets/slide/tv_unit_nature.jpg";
import tvUnitSky from "../../../Assets/slide/tv_unit_sky.jpg";

import kitchenMorning from "../../../Assets/slide/kitchen_morning.jpg";
import kitchenNature from "../../../Assets/slide/kitchen_nature.jpg";
import kitchenSky from "../../../Assets/slide/kitchen_sky.jpg";

import wardrobeMorning from "../../../Assets/slide/wardrobe_morning.jpg";
import wardrobeNature from "../../../Assets/slide/wardrobe_nature.jpg";
import wardrobeSky from "../../../Assets/slide/wardrobe_sky.jpg";

import morning from "../../../Assets/slide/morning.jpg";
import nature from "../../../Assets/slide/nature.jpg";
import sky from "../../../Assets/slide/sky.jpg";
import theme from "../../../Theme";
import ModalSection from "../ModalSection";
import ConsultationForm from "../ConsultationForm/ConsultationForm";

const themeColor = {
  morning: {
    color: "227,179,77",
    contrastText: "0,0,0",
  },
  nature: {
    color: "23,133,116",
    contrastText: "255,255,255",
  },
  sky: {
    color: "102,170,219",
    contrastText: "0,0,0",
  },
};

const bannerItems = [
  {
    id: 1,
    sliderName: "Pooja Ghar",
    sliderIcon: wardrobe,
    sliderTheme: {
      morning: {
        themeImage: morning,
        bannerImage: poojagharMorning,
        bannerText: "Pooja Ghar Morning Theme",
      },
      nature: {
        themeImage: nature,
        bannerImage: poojagharNature,
        bannerText: "Pooja Ghar Nature Theme",
      },
      sky: {
        themeImage: sky,
        bannerImage: poojagharSky,
        bannerText: "Pooja Ghar Sky Theme",
      },
    },
  },
  {
    id: 2,
    sliderName: "Tv Unit",
    sliderIcon: menu,
    sliderTheme: {
      morning: {
        themeImage: morning,
        bannerImage: tvUnitMorning,
        bannerText: "Tv Unit Morning Theme",
      },
      nature: {
        themeImage: nature,
        bannerImage: tvUnitNature,
        bannerText: "Tv Unit Nature Theme",
      },
      sky: {
        themeImage: sky,
        bannerImage: tvUnitSky,
        bannerText: "Tv Unit Sky Theme",
      },
    },
  },
  {
    id: 3,
    sliderName: "Wardrobe",
    sliderIcon: menu,
    sliderTheme: {
      morning: {
        themeImage: morning,
        bannerImage: wardrobeMorning,
        bannerText: "Wardrobe Morning Theme",
      },
      nature: {
        themeImage: nature,
        bannerImage: wardrobeNature,
        bannerText: "Wardrobe Nature Theme",
      },
      sky: {
        themeImage: sky,
        bannerImage: wardrobeSky,
        bannerText: "Wardrobe Sky Theme",
      },
    },
  },
  {
    id: 4,
    sliderName: "Kitchen",
    sliderIcon: menu,
    sliderTheme: {
      morning: {
        themeImage: morning,
        bannerImage: kitchenMorning,
        bannerText: "Kitchen Morning Theme",
      },
      nature: {
        themeImage: nature,
        bannerImage: kitchenNature,
        bannerText: "Kitchen Nature Theme",
      },
      sky: {
        themeImage: sky,
        bannerImage: kitchenSky,
        bannerText: "Kitchen Sky Theme",
      },
    },
  },
];

const HomeBannerNew = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTheme, setCurrentTheme] = useState("morning");
  const [selectedSection, setSelectedSection] = useState(bannerItems[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerItems.length);
    }, 300000); // Change slide every 5 minutes

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const currentItem = bannerItems[currentIndex];
  const themes = Object.keys(selectedSection.sliderTheme);

  // Reorder themes array to move the selected theme to the first position
  const reorderedThemes = [
    currentTheme,
    ...themes.filter((theme) => theme !== currentTheme),
  ];

  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          boxSizing: "border-box",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Box sx={{ flex: 1, position: "relative" }}>
          <Box
            sx={{
              flex: 1,
              height: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <motion.div
              key={currentItem.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                backgroundImage: `url(${selectedSection.sliderTheme[currentTheme].bannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  maxWidth: "600px",
                  position: "absolute",
                  bottom: "100px",
                  left: 0,
                  p: 2,
                  background: `linear-gradient(100deg, rgba(${themeColor[currentTheme].color}, 0.5) 0%,rgba(${themeColor[currentTheme].color}, .3) 25%, rgba(${themeColor[currentTheme].color}, 0) 90%)`,
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography variant="h3">
                  {selectedSection.sliderTheme[currentTheme].bannerText}
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: `rgba(${themeColor[currentTheme].color},.8)`,
                      color: `rgba(${themeColor[currentTheme].contrastText},1)`,
                      px: 1.5,
                      py: 1,
                      boxSizing: "border-box",
                      textTransform: "inherit",
                      whiteSpace: "nowrap",
                      fontWeight: 700,

                      letterSpacing: 1,
                      "&:hover": {
                        backgroundColor: `rgba(${themeColor[currentTheme].color},1)`,
                      },
                    }}
                    onClick={handleClickOpen}
                  >
                    Get Consult
                  </Button>
                </Box>
              </Box>
            </motion.div>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "30px",
              right: "30px",
              display: "flex",
              alignItems: "center",

              padding: "5px",
              borderRadius: "5px",
              gap: 1,
            }}
          >
            {bannerItems.map((item) => (
              <Button
                key={item.id}
                onClick={() => setSelectedSection(item)}
                size="small"
                sx={{ textTransform: "inherit" }}
                color="inherit"
              >
                {item.sliderName}
              </Button>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 0 }}>
          {reorderedThemes.map((theme) => (
            <motion.div
              key={theme}
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 80,
                height: "100%",
                cursor: "pointer",
                position: "relative", // Needed for positioning the overlay
              }}
            >
              <Box
                onClick={() => setCurrentTheme(theme)}
                sx={{
                  backgroundImage: `url(${selectedSection.sliderTheme[theme].themeImage})`,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width: 80,
                  height: "100%",
                  position: "relative",
                  overflow: "hidden", // Ensure the overlay does not overflow the box
                }}
              >
                {/* Overlay effect */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    zIndex: 1, // Ensure the overlay is on top of the image
                  }}
                />
                {/* Hover and active states */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.2)", // Darker overlay on hover
                    },
                    "&:active": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)", // Even darker overlay on active
                    },
                    zIndex: 2, // Ensure this layer is above the image and below the overlay
                  }}
                />
              </Box>
            </motion.div>
          ))}
        </Box>
      </Box>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm />
      </ModalSection>
    </React.Fragment>
  );
};

export default HomeBannerNew;
