import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Frammer Motion
import { AnimatePresence, motion, useCycle } from "framer-motion";

//MUI
import theme from "../../Theme";
import {
  Container,
  Grid,
  SvgIcon,
  IconButton,
  Box,
  Drawer,
  ListItem,
  List,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Images and Icons
import Logo from "../../Assets/logo.png";
import { user, menu } from "../../Assets/icons/icons";
import { Close as CloseIcon } from "@mui/icons-material";
import { maxWidth } from "@mui/system";

const MenuItems = [
  { id: 1, name: "Residence", link: "/design-ideas" },
  { id: 2, name: "Offices", link: "/offices" },
  { id: 3, name: "Cities", link: "/city/kakinada" },
  { id: 4, name: "Careers", link: "/careers" },
  { id: 5, name: "Contact Us", link: "/contact-us" },
];

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
    top: "0",
    zIndex: "99",
  },

  headerwrapper: {
    position: "absolute",
    width: "calc(100%)",
    boxSizing: "border-box",
    overflow: "hidden",
    left: 0,
    transition: "background-color ease 0.5s", // Add the transition property here
    backgroundColor: "transparent", // Use MUI theme background color
  },

  hasAddBg: {
    backgroundColor: `rgba(${theme.palette.text.white},.95)`, // Or any other color you prefer
  },

  list: {
    marginBottom: "10px",
    //justifyContent: "center",
  },
  menuitem: {
    textDecoration: "none",
    fontSize: "50px",
    fontWeight: "700",
    textAlign: "center",
    color: `rgba(${theme.palette.primary.default},1)`,

    "&:hover": {
      color: `rgba(${theme.palette.primary.default},.5)`,
    },
  },
  menudrawer: {
    "& .MuiBackdrop-root": {
      // Customize backdrop styles here
      backgroundColor: "rgba(0, 0, 0, 0.2)", // Change this to your desired color
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  // State to manage header visibility
  const [hidden, setHidden] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [hasAddBg, setHasAddBg] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle scroll events and update header visibility
  function update() {
    const currentScrollY = window.scrollY;
    if (currentScrollY < prevScrollY) {
      setHidden(false);
    } else if (currentScrollY > 200 && currentScrollY > prevScrollY) {
      setHidden(true);
    }
    setPrevScrollY(currentScrollY);

    // Add or remove addbg class based on scroll position
    if (currentScrollY > 300) {
      setHasAddBg(true);
    } else {
      setHasAddBg(false);
    }
  }

  // Effect to add and remove the scroll event listener
  useEffect(() => {
    function handleScroll() {
      update();
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  // Animation variants for header motion
  const headerVariants = {
    hidden: { y: -81 },
  };

  return (
    <React.Fragment>
      {/* Header component */}
      <header className={classes.header}>
        {/* Motion div for header animation */}
        <motion.div
          className={`${classes.headerwrapper} ${
            hasAddBg ? classes.hasAddBg : ""
          }`}
          variants={headerVariants}
          animate={hidden ? "hidden" : "visible"}
          initial="visible"
        >
          {/* Container for header content */}
          <Container maxWidth={false}>
            <Grid
              container
              sx={{
                padding: "18px 0",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                {/* Logo linking to home page */}
                <Box component={Link} to="/">
                  <img src={Logo} alt="Logo" />
                </Box>
              </Grid>
              <Grid>
                {/* IconButton linking to login page */}
                <IconButton component={Link} to="/login">
                  <SvgIcon component={user} />
                </IconButton>
                {/* IconButton for menu (possibly a mobile menu) */}

                <IconButton onClick={toggleDrawer}>
                  <SvgIcon component={menu} />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </motion.div>
      </header>

      {/* //Main Menu        */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }} // Adjust the overlay transition duration
          >
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              anchor="right"
              PaperProps={{
                component: motion.div,
                initial: { width: 0 },
                animate: {
                  width: "50%",
                  backgroundColor: "white",
                  minWidth: 400,
                  maxWidth: 600,
                },
                exit: { width: 0, minWidth: 0 },
                transition: {
                  duration: 0.4, // Adjust the drawer transition duration
                  ease: "easeInOut", // You can also try other easing functions for smoother transitions
                },
              }}
              ModalProps={{
                keepMounted: false,
              }}
              className={classes.menudrawer}
            >
              <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <IconButton onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <List>
                    {MenuItems.map(({ name, link, id }) => (
                      <ListItem
                        className={classes.list}
                        component={motion.li}
                        key={id}
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.4, delay: id * 0.15 }}
                      >
                        <Link key={id} to={link} className={classes.menuitem}>
                          {name}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Drawer>
          </motion.div>
        )}
      </AnimatePresence>
      {/* //Main Menu End        */}
    </React.Fragment>
  );
};

export default Header;
