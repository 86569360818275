import React from "react";
//MUI
import theme from "../../Theme";
import { Box, Container, Typography } from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";

//Image and Icons

import ChairImage from "../../Assets/images/bg-image/chair.png";
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";
import BgFormImage from "../../Assets/images/bg-image/kitchen2.jpg";

//Data
import {
  bannerDetails,
  carouselSettings,
  whatWeOffer,
} from "../../Data/ModularInteriors/ModularInteriors";

function ModularInteriors() {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
        />

        <Section
          backimg={ChairImage}
          backimgposition="0 bottom"
          bgimg={BgImage}
          padding="100px 0 100px 24px"
        >
          <SectionTitle title="What we offer" shadowtext="What we offer" />
          <Typography>
            Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus.
          </Typography>

          <CustomCarouselMain
            type="imagetitlecont"
            data={whatWeOffer}
            showbutton={true}
            settings={carouselSettings}
          />
        </Section>
        <Section bgimg={BgFormImage} bgcolor="0,0,0,.2">
          <Container>
            <ConsultationFormSection title="Transform your dream interiors into reality with a single click" />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
}
export default ModularInteriors;
