import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import BannerImage from "../../Assets/images/wardrobe/Wd05.jpg";

//discoverWardrobes
import His from "../../Assets/images/materials/wardrobes/his_wardrobe.jpg";
import Her from "../../Assets/images/materials/wardrobes/her_wardrobe.jpg";
import Kids from "../../Assets/images/materials/wardrobes/kids_wardrobe.jpg";

//typesOfWardrobes
import SlidingDoor from "../../Assets/images/materials/wardrobes/sliding_door_wardrobe.jpg";
import HingedDoor from "../../Assets/images/materials/wardrobes/hinged_door_wardrobe.jpg";
import WalkIn from "../../Assets/images/materials/wardrobes/walk_in_wardrobe.jpg";

//premiumCoreMaterials
import Acrylic from "../../Assets/dummy.jpg";
import Laminate from "../../Assets/dummy.jpg";
import PVC from "../../Assets/dummy.jpg";

//shuterFinishes
import MatteFinish from "../../Assets/images/materials/wardrobes/matte_finish_wardrobe.jpg";
import GlossyFinish from "../../Assets/images/materials/wardrobes/glossy_finish_wardrobe.jpg";
import TexturedFinish from "../../Assets/images/materials/wardrobes/textured_finish_wardrobe.jpg";
import HighGlossFinish from "../../Assets/images/materials/wardrobes/high_glossy_finish_wardrobe.jpg";
import MirrorFinish from "../../Assets/images/materials/wardrobes/mirror_finish_wardrobe.jpg";
import PatternedFinish from "../../Assets/images/materials/wardrobes/patterned_finish_wardrobe.jpg";

//wardrobeHandles
import PullHandle from "../../Assets/images/materials/wardrobes/pull_handles.jpg";
import CupHandle from "../../Assets/images/materials/wardrobes/cup_handles.jpg";
import RingPull from "../../Assets/images/materials/wardrobes/ring_pull.jpg";
import FlushPull from "../../Assets/images/materials/wardrobes/flush_pull_handles.jpg";
import ShellHandle from "../../Assets/images/materials/wardrobes/shelll_handles.jpg";

//wardrobeCabinets
import Shelves from "../../Assets/dummy.jpg";
import Drawers from "../../Assets/dummy.jpg";
import HangingRods from "../../Assets/dummy.jpg";
import PullOutTrays from "../../Assets/dummy.jpg";
import ShoeRacks from "../../Assets/dummy.jpg";
import TieBeltRacks from "../../Assets/dummy.jpg";
import ValetRods from "../../Assets/dummy.jpg";
import IroningBoard from "../../Assets/dummy.jpg";
import PantsRacks from "../../Assets/dummy.jpg";
import MirrorCabinets from "../../Assets/dummy.jpg";
import BasketsBins from "../../Assets/dummy.jpg";
import FoldOutDesks from "../../Assets/dummy.jpg";
import LingerieDrawers from "../../Assets/dummy.jpg";
import HiddenCompartments from "../../Assets/dummy.jpg";
import FileDrawers from "../../Assets/dummy.jpg";
import MediaStorage from "../../Assets/dummy.jpg";
import PullOutMirrors from "../../Assets/dummy.jpg";

//wardrobeAccessories
import DrawerOrganizers from "../../Assets/images/materials/wardrobes/drawer_orgainiser.jpg";
import ShelfDividers from "../../Assets/images/materials/wardrobes/shelf_dividers.jpg";
import HangingOrganizers from "../../Assets/images/materials/wardrobes/hanging_organizers.jpg";
import PullOutTraysAcc from "../../Assets/images/materials/wardrobes/pull_out_trays.jpg";
import BeltTieRacks from "../../Assets/images/materials/wardrobes/belt_tie_racks.jpg";
import JewelryTrays from "../../Assets/images/materials/wardrobes/jewelry_trays.jpg";
import PantsRacksAcc from "../../Assets/images/materials/wardrobes/pants_racks.jpg";
import ValetRodsAcc from "../../Assets/images/materials/wardrobes/valet_rods.jpg";
import IroningBoardAcc from "../../Assets/images/materials/wardrobes/ironing_board.jpg";
import FoldDownDesk from "../../Assets/dummy.jpg";
import DrawerLiners from "../../Assets/dummy.jpg";
import WireBaskets from "../../Assets/images/materials/wardrobes/wire_baskets.jpg";
import ShoeInserts from "../../Assets/images/materials/wardrobes/shoe_inserts.jpg";
import ShelfLifts from "../../Assets/images/materials/wardrobes/shelf_lifts.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/wardrobe/Wd04.jpg";

// Parallex
import BgScrolling from "../../Assets/images/wardrobe/Wd05.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "The Ambience Wardrobe Range",
  desc: "Choose from our diverse range of wardrobes tailored to accommodate different styles and budgets.",
  image: BannerImage,
  bannertext: false,
};

const discoverWardrobes = [
  {
    id: 1,
    title: "His",
    imageUrl: His,
    desc: "With Organisers, Shelves for bulky clothes, and double hanger rails.",
  },
  {
    id: 2,
    title: "Her",
    imageUrl: Her,
    desc: "With Pullout Hangers rails for colthes, and lockers for Valuables",
  },
  {
    id: 3,
    title: "Kids",
    imageUrl: Kids,
    desc: "With Accessible bottom drawers, Open Shelves and cubby holes",
  },
];

const discoverWardrobesSettings = {
  autoplay: false,
  responsive: {
    0: {
      items: 1.1,
    },
    600: {
      items: 1.2,
    },
    900: {
      items: 3,
    },
  },
};

const typesOfWardrobes = [
  {
    id: 1,
    title: "Sliding Door Wardrobe",
    imageUrl: SlidingDoor,
    desc: "Features doors that slide horizontally on a track, saving space by not requiring door swing clearance.",
  },
  {
    id: 2,
    title: "Hinged Door Wardrobe",
    imageUrl: HingedDoor,
    desc: "Equipped with doors that open outward on hinges, similar to standard doors.",
  },
  {
    id: 3,
    title: "Walk-In Wardrobe",
    imageUrl: WalkIn,
    desc: "A larger storage area that you can walk into, often with ample space for clothes, accessories, and sometimes even a dressing area.",
  },
];

const premiumCoreMaterials = [
  {
    id: 1,
    title: "Acrylic or High-Gloss Shutter",
    imageUrl: Acrylic,
    desc: "Provides a glossy, modern look with a reflective surface.",
  },
  {
    id: 2,
    title: "Laminate",
    imageUrl: Laminate,
    desc: "",
  },
  {
    id: 3,
    title: "Pvc",
    imageUrl: PVC,
    desc: "",
  },
];

const shuterTypes = [
  {
    id: 1,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
  {
    id: 2,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
  {
    id: 3,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
  {
    id: 4,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
  {
    id: 5,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
  {
    id: 6,
    title: "Shuter Types",
    imageUrl: Slide01,
    desc: "",
  },
];
const shuterFinishes = [
  {
    id: 1,
    title: "Matte Finish",
    imageUrl: MatteFinish,
    desc: "A non-reflective, flat finish that adds a subtle and sophisticated look to the shutters.",
  },
  {
    id: 2,
    title: "Glossy Finish",
    imageUrl: GlossyFinish,
    desc: "Provides a reflective, shiny surface that adds a modern and sleek appearance.",
  },
  {
    id: 3,
    title: "Textured Finish",
    imageUrl: TexturedFinish,
    desc: "Offers a tactile experience with various patterns or textures, adding depth to the shutters.",
  },
  {
    id: 4,
    title: "High Gloss Finish",
    imageUrl: HighGlossFinish,
    desc: "A highly reflective and polished surface that adds a luxurious and contemporary feel.",
  },
  {
    id: 5,
    title: "Mirror Finish",
    imageUrl: MirrorFinish,
    desc: "Incorporates mirrored panels that reflect light and create an illusion of space.",
  },
  {
    id: 5,
    title: "Patterned Finish",
    imageUrl: PatternedFinish,
    desc: "Features decorative patterns or designs on the shutters, adding a unique visual element.",
  },
];
const wardrobeHandles = [
  // {
  //   id: 1,
  //   title: "Regular Handles",
  //   imageUrl: Slide01,
  //   desc: "Experience hassle-free maintenance and a wide array of design options with our modular solutions.",
  // },
  // {
  //   id: 2,
  //   title: "Nobbs",
  //   imageUrl: Slide01,
  //   desc: "Rounded or shaped components designed for pull-outs.",
  // },
  {
    id: 3,
    title: "Pull Handle",
    imageUrl: PullHandle,
    desc: "A longer handle that is pulled to open the door, often used for larger and heavier doors.",
  },
  {
    id: 4,
    title: "Cup Handle",
    imageUrl: CupHandle,
    desc: "Features a semi-circular cup shape, making it easy to grip. It's often used on drawers but can also be used on wardrobe doors.    ",
  },
  {
    id: 5,
    title: "Ring Pull",
    imageUrl: RingPull,
    desc: "A handle with a ring-shaped grip, providing a unique and decorative touch to the doors.",
  },
  {
    id: 6,
    title: "Flush Pull",
    imageUrl: FlushPull,
    desc: "A handle that is recessed into the door, creating a sleek and seamless appearance.",
  },
  {
    id: 7,
    title: "Shell Handle",
    imageUrl: ShellHandle,
    desc: "Resembling a seashell, this handle design adds a touch of elegance to the doors.",
  },
];

const wardrobeCabinets = [
  {
    id: 1,
    title: "Shelves",
    imageUrl: Shelves,
    desc: "Adjustable or fixed shelves provide space for folded clothes, shoes, accessories, and other items.",
  },
  {
    id: 2,
    title: "Drawers",
    imageUrl: Drawers,
    desc: "Drawer units are ideal for storing smaller items like socks, underwear, and accessories.    ",
  },
  {
    id: 3,
    title: "Hanging Rods",
    imageUrl: HangingRods,
    desc: "Horizontal rods are used for hanging clothes like shirts, blouses, and pants.    ",
  },
  {
    id: 4,
    title: "Pull-Out Trays",
    imageUrl: PullOutTrays,
    desc: "Pull-out trays offer easy access to folded clothes and accessories.    ",
  },

  {
    id: 5,
    title: "Shoe Racks",
    imageUrl: ShoeRacks,
    desc: "Specialized shelving designed to accommodate shoes in an organized manner.    ",
  },
  {
    id: 6,
    title: "Tie and Belt Racks",
    imageUrl: TieBeltRacks,
    desc: "Hooks or racks for neatly hanging ties, belts, scarves, and other accessories.    ",
  },
  {
    id: 7,
    title: "Valet Rods",
    imageUrl: ValetRods,
    desc: "Extendable rods for temporarily hanging clothes, planning outfits, or packing.",
  },
  {
    id: 8,
    title: "Pull-Out Ironing Board",
    imageUrl: IroningBoard,
    desc: "A concealed ironing board that can be pulled out when needed and stored away.    ",
  },
  {
    id: 9,
    title: "Pants Racks",
    imageUrl: PantsRacks,
    desc: "Pull-out racks with multiple bars for hanging pants without creasing.    ",
  },
  {
    id: 10,
    title: "Mirror Cabinets",
    imageUrl: MirrorCabinets,
    desc: "Cabinet doors that conceal mirrors, combining storage with functionality.    ",
  },
  {
    id: 11,
    title: "Baskets or Bins",
    imageUrl: BasketsBins,
    desc: "Storage containers for organizing items like handbags, scarves, or seasonal clothing.",
  },
  {
    id: 12,
    title: "Fold-Out Desks",
    imageUrl: FoldOutDesks,
    desc: "A fold-out desk or workspace hidden within the wardrobe for various tasks.",
  },
  {
    id: 13,
    title: "Lingerie Drawers",
    imageUrl: LingerieDrawers,
    desc: "Smaller, shallow drawers designed for storing delicate items.",
  },
  {
    id: 14,
    title: "Hidden Compartments",
    imageUrl: HiddenCompartments,
    desc: "Concealed compartments for storing valuables or items that require extra security.    ",
  },
  {
    id: 15,
    title: "File Drawers",
    imageUrl: FileDrawers,
    desc: "Useful for keeping documents or personal paperwork organized.",
  },
  {
    id: 16,
    title: "Media Storage",
    imageUrl: MediaStorage,
    desc: "Shelves or compartments for storing electronic devices, chargers, or entertainment items.",
  },
  {
    id: 17,
    title: "Pull-Out Mirrors",
    imageUrl: PullOutMirrors,
    desc: "Mirrors that can be pulled out for grooming or dressing purposes.",
  },
];

const wardrobeAccessories = [
  {
    id: 1,
    title: "Drawer Organizers",
    imageUrl: DrawerOrganizers,
    desc: "Dividers and trays for drawers, keeping smaller items like socks, underwear, and accessories organized.",
  },
  {
    id: 2,
    title: "Shelf Dividers",
    imageUrl: ShelfDividers,
    desc: "Separate sections within shelves to prevent items from mixing or falling over.    ",
  },
  {
    id: 3,
    title: "Hanging Organizers",
    imageUrl: HangingOrganizers,
    desc: "Hooks or pouches that hang from the rod, providing additional storage for shoes, accessories, or small items.",
  },
  {
    id: 4,
    title: "Pull-Out Trays",
    imageUrl: PullOutTraysAcc,
    desc: "Slide-out trays that provide easy access to folded clothes or accessories.",
  },
  {
    id: 5,
    title: "Belt and Tie Racks",
    imageUrl: BeltTieRacks,
    desc: "Specialized racks for neatly hanging belts, ties, scarves, and accessories.",
  },
  {
    id: 6,
    title: "Jewelry Trays",
    imageUrl: JewelryTrays,
    desc: "Divided compartments for organizing jewelry, watches, and small accessories.",
  },
  {
    id: 7,
    title: "Pants Racks",
    imageUrl: PantsRacksAcc,
    desc: "Pull-out racks with multiple bars for hanging pants without creasing.",
  },
  {
    id: 8,
    title: "Valet Rods",
    imageUrl: ValetRodsAcc,
    desc: "Extendable rods for temporarily hanging clothes, planning outfits, or packing.",
  },
  {
    id: 9,
    title: "Pull-Out Ironing Board",
    imageUrl: IroningBoardAcc,
    desc: "A concealed ironing board that can be pulled out when needed and stored away.",
  },
  {
    id: 10,
    title: "Fold-Down Desk",
    imageUrl: FoldDownDesk,
    desc: "A workspace that can be folded down when not in use, ideal for smaller wardrobes.",
  },
  {
    id: 11,
    title: "Drawer Liners",
    imageUrl: DrawerLiners,
    desc: "Liners that protect drawers and shelves while adding a touch of style.    ",
  },
  {
    id: 12,
    title: "Wire Baskets",
    imageUrl: WireBaskets,
    desc: "Pull-out wire baskets for storing various items in an organized manner.    ",
  },
  {
    id: 13,
    title: "Shoe Inserts",
    imageUrl: ShoeInserts,
    desc: "Insertable compartments that keep shoes separate and organized    ",
  },
  {
    id: 14,
    title: "Shelf Lifts",
    imageUrl: ShelfLifts,
    desc: "Mechanisms that allow you to adjust the height of shelves for varying storage needs.    ",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.3",
};

export {
  bannerDetails,
  discoverWardrobes,
  discoverWardrobesSettings,
  typesOfWardrobes,
  premiumCoreMaterials,
  shuterTypes,
  shuterFinishes,
  wardrobeHandles,
  wardrobeCabinets,
  wardrobeAccessories,
  PrallexDetails,
  consultationFormSectionDetails,
};
