import React from "react";
//MUI
import theme from "../../../Theme";
import {
  Typography,
  Box,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
//custom component

//Images and Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//data
import { Faqs as faqsData } from "../../../Data/Faqs";

const Faqs = () => {
  const [value, setValue] = React.useState(0);
  const [expandedAccordions, setExpandedAccordions] = React.useState([0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setExpandedAccordions([0]);
  };

  const handleAccordionChange = (accordionIndex) => {
    const currentIndex = expandedAccordions.indexOf(accordionIndex);
    let newExpanded = [];

    if (currentIndex === -1) {
      newExpanded = [accordionIndex];
    }

    setExpandedAccordions(newExpanded);
  };
  return (
    <React.Fragment>
      {faqsData.map((accordion, accordionIndex) => (
        <Accordion
          key={accordionIndex}
          expanded={expandedAccordions.includes(accordionIndex)}
          onChange={() => handleAccordionChange(accordionIndex)}
          sx={{
            boxShadow:
              "0px 13px 27px -5px rgba(50, 50, 93, 0.25), 0px 8px 16px -8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: `rgba(${theme.palette.text.default},.08)`,
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              {accordion.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "20px",
              backgroundColor: `rgba(${theme.palette.text.default},.03)`,
            }}
          >
            <Typography variant="body1">
              {Object.values(accordion.content).map((answer, index) => (
                <Typography key={index}>{answer}</Typography>
              ))}
            </Typography>
            {/* Add additional MUI components here */}
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
};

export default Faqs;
