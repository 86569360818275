import React from "react";
//MUI
import theme from "../../Theme";
import { Box, Container, Grid, Typography } from "@mui/material";
//Custom Components
import Section from "../../Component/HelperComponents/Section";
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";

//Image and Icons

import BgConsultationFormSection from "../../Assets/dummy.jpg";

//Data
import {
  BannerDetails,
  DesignIdeasGallery,
  consultationFormSectionDetails,
} from "../../Data/DesignIdeas/DesignIdeas";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";

const DesignIdeas = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          title={BannerDetails.title}
          desc={BannerDetails.desc}
          image={BannerDetails.image}
        />
        <Section>
          <CustomGallery data={DesignIdeasGallery} showcategories={true} />
        </Section>

        <Section
          bgimg={consultationFormSectionDetails.image}
          bgcolor={consultationFormSectionDetails.bgcolor}
        >
          <Container>
            <ConsultationFormSection
              title={consultationFormSectionDetails.title}
            />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default DesignIdeas;
