import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import BannerImage from "../../Assets/images/crockeryunits/Cu31.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/crockeryunits/Cu39.jpg";

//typesOfCrockeryUnits
import Modern from "../../Assets/images/materials/crockeryunits/modern.jpg";
import Industrial from "../../Assets/images/materials/crockeryunits/industrial.jpg";
import Scandinavian from "../../Assets/images/materials/crockeryunits/scandinavian.jpg";
import Minimalist from "../../Assets/images/materials/crockeryunits/minimalist.jpg";
import Artisanal from "../../Assets/images/materials/crockeryunits/artisanal.jpg";

// Parallex
import BgScrolling from "../../Assets/images/crockeryunits/Cu28.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Handcrafted Crokery for Every Occasion",
  desc: "space optimization. Our wall-mounted crockery units not only provide a stylish storage solution but also free up valuable floor space, making them an ideal choice for smaller kitchens or dining areas.",
  image: BannerImage,
  bannertext: false,
};

const typesOfCrockeryUnits = [
  {
    id: 1,
    title: "Modern",
    imageUrl: Modern,
    desc: " Embraces clean lines, minimalistic design, and sleek materials like glass, metal, and acrylic for a contemporary aesthetic.",
  },
  {
    id: 2,
    title: "Industrial",
    imageUrl: Industrial,
    desc: "Utilizes raw materials like metal and wood, often with exposed hardware and a utilitarian vibe for an edgy and urban look.",
  },
  {
    id: 3,
    title: "Scandinavian",
    imageUrl: Scandinavian,
    desc: "Features light colors, natural wood tones, and functional design, creating a simple and cozy atmosphere.",
  },
  {
    id: 4,
    title: "Minimalist",
    imageUrl: Minimalist,
    desc: "Focuses on essentials, often with hidden storage and a clutter-free display for a serene and uncluttered look.",
  },
  {
    id: 5,
    title: "Artisanal",
    imageUrl: Artisanal,
    desc: "Features handcrafted or artisanal elements, showcasing unique details and craftsmanship.",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export {
  bannerDetails,
  typesOfCrockeryUnits,
  PrallexDetails,
  consultationFormSectionDetails,
};
