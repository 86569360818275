import React from "react";
import { Link } from "react-router-dom";
//MUI
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  SvgIcon,
  Button,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import Faqs from "../../Component/HelperComponents/Faqs/Faqs";
import LineHeader from "../../Component/HelperComponents/LineHeader";
import TestimonialSection from "../../Component/HelperComponents/TestimonialSection";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";

//Image and Icons
import BgTestimonials from "../../Assets/images/bg-image/sofa.png";
import BannerImage from "../../Assets/page-banner/modular-interiors.jpg";
import TubImage from "../../Assets/images/bg-image/tub.png";
import BgFormImage from "../../Assets/images/fullhomeinteriors/Fhi02.jpg";

//Data
import {
  bannerDetails,
  whatWeOffer,
  carouselSettings,
} from "../../Data/FullHomeInteriors/FullHomeInteriors";

import { testimonialsSettings, Testimonials } from "../../Data/Testimonials";

const FullHomeInteriors = () => {
  //Form Style
  const classes = FromFieldStyles();
  return (
    <React.Fragment>
      <DefaultLayout>
        <Container maxWidth={false} disableGutters={false}>
          <InnerBanner
            title={bannerDetails.title}
            desc={bannerDetails.desc}
            image={bannerDetails.bannerimage}
          />
        </Container>
        <Section
          backimg={TubImage}
          backimgposition="0 bottom"
          bgimg={BannerImage}
          padding="100px 0 100px 24px"
        >
          <SectionTitle title="What we offer" shadowtext="What we offer" />
          <Typography sx={{ maxWidth: "480px" }}>
            Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus.
          </Typography>

          <CustomCarouselMain
            type="imagetitle"
            data={whatWeOffer}
            showbutton={true}
            settings={carouselSettings}
          />
        </Section>

        {/* Section Testimonials */}
        <Section backimg={BgTestimonials} backimgposition="0 bottom">
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}>
                <LineHeader
                  title="Hear the Stories and Testimonials"
                  title2="That Showcase Our Commitment to Excellence."
                  col="primary"
                />
                <SectionTitle title="Let Our Clients Speak" />
              </Grid>
              <Grid></Grid>
            </Grid>
            <Grid container>
              <Grid>
                <CustomCarouselMain
                  data={Testimonials}
                  type={"testimonials"}
                  settings={testimonialsSettings}
                />
              </Grid>
            </Grid>
          </Container>
        </Section>
        <Section bgcolor="white">
          <Container maxWidth={"lg"}>
            <Faqs />
          </Container>
        </Section>
        <Section bgimg={BgFormImage} bgcolor="0,0,0,.2">
          <Container>
            <ConsultationFormSection title="Transform your dream interiors into reality with a single click" />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default FullHomeInteriors;
