import React from 'react';
//Custom Components
import DefaultLayout from '../../Component/Layout/DefaultLayout';
import InnerBannner from '../../Component/HelperComponents/BannerComponent/InnerBanner';

const Offerings = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner />
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Offerings;
